import React from "react";
import { useLocation, useParams } from "react-router-dom";

// HOC para passar o `location` para o componente de classe
function withLocation(Component) {
  return function WrapperComponent(props) {
    const location = useLocation();
    const params = useParams();
    return <Component {...props} location={location} params={params} />;
  };
}

export default withLocation;
