import React, { Component } from "react";

import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faBorderTopLeft,
  faCog,
  faPen,
  faPlus,
  faStore,
  faTrash,
  fawhatsapp,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { apiUrl } from "../../../../comps/apiUrl";
import withLocation from "../../../../comps/withLocation";
import { StaticDialog } from "react-st-modal";

class Locations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      data: {},
      trash: {},
      error: false,
    };
  }

  componentDidMount() {
    document.title = "Carregando...";
    fetch(`${apiUrl}/store/${this.props.params.token}/locations`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        if (response.status == 404) {
          this.setState({
            loader: false,
          });
        }

        if (response.status == 200) {
          response.json().then((data) => {
            document.title = data.store.name + " > locações";
            this.setState({
              store: data.store,
              locations: data.locations,

              loader: false,
            });
          });
        }
      })
      .catch((error) => {
        toast.error("Ocorreu um erro ao carregar as maquinas");
      });
  }

  formatDate(dateString) {
    var e = dateString.split("-");
    var dateString = e[2] + "/" + e[1] + "/" + e[0];
    return dateString;
  }

  render() {
    if (this.state.loader) {
      return (
        <div class="area-loader">
          <div class="loader"></div>
        </div>
      );
    }

    return (
      <div class="content d-flex justify-content-center">
        <div class="col-8 mt-5">
          <div class="card">
            <div class="card-header d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <strong>
                  {this.state.store.name}{" "}
                  <FontAwesomeIcon icon={faAngleRight} /> Locações
                </strong>
              </div>
              <button
                className="btn btn-primary"
                onClick={() => {
                  this.props.navigate(
                    `/stores/${this.props.params.token}/locations/add`
                  );
                }}
              >
                <FontAwesomeIcon icon={faPlus} /> Adicionar item
              </button>
            </div>
            <div class="card-body">
              {this.state.locations.length === 0 ? (
                <div className="d-flex justify-content-center align-items-center">
                  <h4>Voce ainda nao possui locacoes</h4>
                </div>
              ) : (
                <>
                  <div>
                    <span>Itens disponiveis para locação:</span>
                  </div>
                  <table class="table table-striped table-bordered arvore-l mt-2">
                    <thead class="thead-dark">
                      <tr>
                        <th>Nome</th>
                        <th>Quantidade</th>
                        <th>Opções</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.locations.map((location) => (
                        <tr>
                          <td>{location.name}</td>
                          <td>{location.qtd}</td>
                          <td className="btns">
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                this.props.navigate(
                                  `/stores/${this.props.params.token}/locations/${location.id}/edit`
                                );
                              }}
                            >
                              <FontAwesomeIcon icon={faPen} />
                            </button>
                            <button
                              className="btn btn-danger"
                              onClick={() => {
                                this.setState({ trash: location });
                              }}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          </div>
        </div>
        <StaticDialog
          isOpen={Object.keys(this.state.trash).length > 0}
          title="Tem certeza de que deseja continuar?
"
          onAfterClose={(result) => {
            this.setState({ trash: {} });
          }}
        >
          <div style={{ padding: 10 }}>
            Se você confirmar a exclusão, não será possível recuperar os dados
            posteriormente.
          </div>
          <div className="modal-fotter">
            <button
              className="btn btn-danger"
              onClick={(e) => {
                e.target.classList.add("load");
                fetch(
                  `${apiUrl}/store/${this.props.params.token}/locations/${this.state.trash.id}`,
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      authorization: localStorage.getItem("token"),
                    },
                    body: JSON.stringify({
                      id: this.state.trash.id,
                    }),
                  }
                )
                  .then((response) => {
                    e.target.classList.remove("load");
                    if (response.status == 200) {
                      response.json().then((data) => {
                        var id = this.state.trash.id;
                        this.setState((prevState) => ({
                          locations: prevState.locations.filter(
                            (item) => item.id !== id
                          ),
                          trash: {},
                        }));
                        toast.success("Item excluido com sucesso");
                      });
                    }
                  })
                  .catch((error) => {
                    e.target.classList.remove("load");
                    toast.error("Ocorreu um erro ao excluir o item");
                  });
              }}
            >
              <FontAwesomeIcon icon={faTrash} /> Exluir
            </button>
          </div>
        </StaticDialog>
      </div>
    );
  }
}

export default withLocation(Locations);
