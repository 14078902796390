import React, { Component } from "react";
import { Link } from "react-router-dom";
import atencao from "../../img/atencao.svg";
import logo from "../../img/logo.png";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextField, Button } from "@mui/material";
import {
  faBuilding,
  faClipboard,
  faCoffee,
  faEnvelope,
  faEye,
  faFileInvoice,
  faHome,
  faIdCard,
  faMapMarkerAlt,
  faMobileAlt,
  faPen,
  faPhone,
  faSave,
  faSearch,
  faTrash,
  faUser,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { StaticDialog, useDialog } from "react-st-modal";
import InputMask from "react-input-mask";
import { apiUrl } from "../../comps/apiUrl";
import { toast } from "react-toastify";

class CadastroClientes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      add: false,
      dados: {},
      items: [],
      currentPage: 1,
      totalItems: 0,
      itemsPerPage: 100,
      edit: {},
      trash: {},
      cpfCnpj: "",
    };
  }

  componentDidMount() {
    document.title = "Cadastro de clientes";
    this.fetchData(this.state.currentPage);
  }

  handleCpfCnpjChange = (e) => {
    console.log(e.target);
    function cnpj(v) {
      v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
      v = v.replace(/^(\d{2})(\d)/, "$1.$2"); //Coloca ponto entre o segundo e o terceiro dígitos
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3"); //Coloca ponto entre o quinto e o sexto dígitos
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2"); //Coloca uma barra entre o oitavo e o nono dígitos
      v = v.replace(/(\d{4})(\d)/, "$1-$2"); //Coloca um hífen depois do bloco de quatro dígitos
      return v;
    }

    function cpf(v) {
      v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
      v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
      v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
      //de novo (para o segundo bloco de números)
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); //Coloca um hífen entre o terceiro e o quarto dígitos
      return v;
    }

    if (e.target.value.length > 18) return;

    if (e.target.value.length <= 14) {
      this.setState({
        cpfCnpj: cpf(e.target.value),
      });
    } else {
      this.setState({
        cpfCnpj: cnpj(e.target.value),
      });
    }
  };
  fetchData(page) {
    fetch(`${apiUrl}/clientes?page=${page}&limit=${this.state.itemsPerPage}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response.json().then((data) => {
        const totalItems = data.totalItems; // Simulado, substitua pelo valor real do backend.
        const totalPages = Math.ceil(totalItems / this.state.itemsPerPage);

        this.setState({
          items: data.items,
          currentPage: page,
          totalPages,
          loader: false,
        });
      });
    });
  }

  handlePageClick = (page) => {
    this.fetchData(page);
  };
  handleNextPage = () => {
    this.fetchData(this.state.currentPage + 1);
  };

  handlePreviousPage = () => {
    if (this.state.currentPage > 1) {
      this.fetchData(this.state.currentPage - 1);
    }
  };

  renderPagination() {
    const { currentPage, totalPages } = this.state;

    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => this.handlePageClick(i)}
          style={{
            backgroundColor: i === currentPage ? "#007bff" : "#fff",
            color: i === currentPage ? "#fff" : "#000",
            border: "1px solid #ddd",
            cursor: "pointer",
          }}
        >
          {i}
        </button>
      );
    }

    return (
      <div>
        <button
          className="btn btn-ligth"
          onClick={() => this.handlePageClick(currentPage - 1)}
          disabled={currentPage === 1}
          style={{
            cursor: currentPage === 1 ? "not-allowed" : "pointer",
          }}
        >
          &lt;
        </button>

        {pages}

        <button
          onClick={() => this.handlePageClick(currentPage + 1)}
          className="btn btn-ligth"
          disabled={currentPage === totalPages}
          style={{
            cursor: currentPage === totalPages ? "not-allowed" : "pointer",
          }}
        >
          &gt;
        </button>
      </div>
    );
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      dados: {
        ...prevState.dados, // Mantém os outros campos do objeto `dados`
        [name]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };

  handleEditChange = (event) => {
    const { name, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      edit: {
        ...prevState.edit, // Mantém os outros campos do objeto `dados`
        [name]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };

  handleEdit = (event) => {
    const { id, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      edit: {
        ...prevState.edit, // Mantém os outros campos do objeto `dados`
        [id]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };

  render() {
    if (this.state.loader) {
      return (
        <div class="area-loader">
          <div class="loader"></div>
        </div>
      );
    }

    return (
      <>
        <div class="content">
          <div class="card">
            <div class="card-header d-flex align-items-center justify-content-between">
              <strong>Cadastro e Lista de Clientes</strong>
              <button
                class="btn btn-primary"
                onClick={() => {
                  this.setState({ add: true });
                }}
              >
                {" "}
                <FontAwesomeIcon icon={faUserPlus} /> Cadastrar cliente
              </button>
            </div>
            <div class="card-body">
              {this.state.items.length > 0 ? (
                <>
                  <div class="mb-3 d-flex justify-content-center">
                    <div class="input-group w-50">
                      <input
                        type="text"
                        class="form-control"
                        id="searchInput"
                        onChange={(e) => {
                          this.setState({ search: e.target.value });
                        }}
                        placeholder="Pesquisar..."
                      />
                      <button
                        class="btn btn-primary"
                        onClick={(e) => {
                          console.log("aaaa");
                          e.target.classList.add("load");
                          var search = this.state.search;
                          fetch(`${apiUrl}/buscar-cliente?s=${search}`, {
                            method: "GET",
                            headers: {
                              "Content-Type": "application/json",
                            },
                          })
                            .then((response) => response.json())
                            .then((data) => {
                              e.target.classList.remove("load");
                              this.setState({ items: data });
                            });
                        }}
                      >
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </div>
                  </div>
                  <table class="table table-striped table-bordered arvore-l">
                    <thead class="thead-dark">
                      <tr>
                        <th>Cod. cliente</th>
                        <th>Nome do Cliente</th>
                        <th>CNPJ/CPF</th>
                        <th>Telefone</th>
                        <th>Cidade</th>
                        <th>Opções</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.items.map((item, index) => (
                        <>
                          <tr>
                            <td>{item.id}</td>
                            <td>{item.nome}</td>
                            <td>{item.cnpj_cpf}</td>
                            <td>{item.telefone}</td>
                            <td>{item.cidade}</td>

                            <td className="btns">
                              <Link
                                to={`/cadastro-clientes/view/${item.id}`}
                                className="btn btn-success"
                              >
                                <FontAwesomeIcon icon={faEye} />
                              </Link>
                              <button
                                className="btn btn-primary"
                                onClick={(e) => {
                                  this.setState({ edit: item });
                                }}
                                style={{ borderRadius: 0 }}
                              >
                                <FontAwesomeIcon icon={faPen} />
                              </button>
                              <button
                                className="btn btn-danger"
                                onClick={() => {
                                  this.setState({ trash: item });
                                }}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <>Não há resultados</>
              )}
            </div>

            <div
              className="card-footer"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {this.renderPagination()}
            </div>
          </div>
        </div>

        <StaticDialog
          isOpen={Object.keys(this.state.trash).length > 0}
          title="Tem certeza de que deseja continuar?"
          onAfterClose={(result) => {
            this.setState({ trash: {} });
          }}
        >
          <div style={{ margin: 10 }}>
            Se você confirmar a exclusão, não será possível recuperar os dados
            posteriormente.
          </div>

          <div className="modal-fotter">
            <button
              className="btn btn-danger"
              onClick={(e) => {
                e.target.classList.add("load");
                var id = this.state.trash.id;
                fetch(`${apiUrl}/deletar-cliente`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    // outras headers, se necessário
                  },
                  body: JSON.stringify({ id }), // corpo da requisição em formato JSON
                })
                  .then((response) => {
                    e.target.classList.remove("load");
                    if (response.ok) {
                      this.setState((prevState) => ({
                        items: prevState.items.filter((item) => item.id !== id),
                        trash: {},
                      }));

                      toast.success("Cliente excluido com sucesso");
                    }
                  })
                  .catch((error) => {
                    toast.error("Erro ao enviar dados:", error);
                  });
              }}
            >
              <FontAwesomeIcon icon={faTrash} /> Excluir
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={this.state.add}
          title="Cadastrar Cliente"
          onAfterClose={(result) => {
            this.setState({ add: false });
          }}
        >
          <div className="container">
            <form
              id="cadastroForm"
              onSubmit={(e) => {
                var dados = this.state.dados;
                e.preventDefault();
                console.log(dados);
              }}
            >
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    label="Nome"
                    name="nome"
                    onChange={this.handleChange}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    label="CPF/CNPJ"
                    name="cpf_cnpj"
                    value={this.state.cpfCnpj}
                    onChange={(e) => this.handleCpfCnpjChange(e)}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <InputMask
                    name="telefone"
                    onChange={this.handleChange}
                    mask="(99) 9999-9999" // Máscara de telefone
                  >
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        label="Telefone"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          style: {
                            height: 45,
                            padding: "0 5px",
                          },
                        }}
                        margin="normal"
                      />
                    )}
                  </InputMask>
                </div>
                <div className="col-md-6">
                  <InputMask
                    name="celular"
                    onChange={this.handleChange}
                    mask="(99) 9 9999-9999" // Máscara de telefone
                  >
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        label="Celular"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          style: {
                            height: 45,
                            padding: "0 5px",
                          },
                        }}
                        margin="normal"
                      />
                    )}
                  </InputMask>
                </div>
              </div>
              <div>
                <TextField
                  label="E-mail"
                  name="email"
                  onChange={this.handleChange}
                  variant="outlined" // Pode ser "filled" ou "standard"
                  fullWidth // O campo ocupa toda a largura disponível
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />
              </div>

              <div className="row">
                <div className="col-md-6">
                  <InputMask
                    name="cep"
                    onChange={this.handleChange}
                    mask="99.999-999" // Máscara de telefone
                  >
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        label="CEP:"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          style: {
                            height: 45,
                            padding: "0 5px",
                          },
                        }}
                        margin="normal"
                      />
                    )}
                  </InputMask>
                </div>
                <div className="col-md-6">
                  <TextField
                    label="Endereço"
                    name="endereco"
                    onChange={this.handleChange}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <TextField
                    label="Número"
                    name="numero"
                    onChange={this.handleChange}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    label="Bairro"
                    name="bairro"
                    onChange={this.handleChange}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <TextField
                    label="Cidade"
                    name="cidade"
                    onChange={this.handleChange}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    label="Estado"
                    name="estado"
                    onChange={this.handleChange}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />
                </div>
              </div>
            </form>
          </div>

          <div className="modal-fotter">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                var dados = this.state.dados;

                fetch(`${apiUrl}/cadastrar-cliente`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    // outras headers, se necessário
                  },
                  body: JSON.stringify({
                    nome: dados.nome,
                    cnpj_cpf: this.state.cpfCnpj,
                    email: dados.email,
                    telefone: dados.telefone,
                    celular: dados.celular,
                    cep: dados.cep,
                    endereco: dados.endereco,
                    numero: dados.numero,
                    bairro: dados.bairro,
                    cidade: dados.cidade,
                    uf: dados.estado,
                  }), // corpo da requisição em formato JSON
                })
                  .then((response) => {
                    if (response.status == 200) {
                      response.json().then((data) => {
                        this.setState((prevState) => ({
                          items: [
                            {
                              id: data.id,
                              nome: dados.nome,
                              cnpj_cpf: this.state.cpfCnpj,
                              email: dados.email,
                              telefone: dados.telefone,
                              celular: dados.celular,
                              cep: dados.cep,
                              endereco: dados.endereco,
                              numero: dados.numero,
                              bairro: dados.bairro,
                              cidade: dados.cidade,
                              uf: dados.estado,
                            },
                            ...prevState.items,
                          ],
                          add: false,
                          cpfCnpj: "",
                        }));

                        toast.success("Cliente adicionado");
                      });
                    }
                  })
                  .catch((err) => {
                    toast.error("Falha na comunicação com o servidor!");
                  });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.edit).length > 0}
          title="Editar Cliente"
          onAfterClose={(result) => {
            this.setState({ edit: {} });
          }}
        >
          <div className="container">
            <form
              id=""
              onSubmit={(e) => {
                var dados = this.state.dados;
                e.preventDefault();
                console.log(dados);
              }}
            >
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    label="Nome"
                    name="nome"
                    onChange={this.handleEditChange}
                    value={this.state.edit.nome || ""}
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    label="CPF/CNPJ"
                    name="cpf_cnpj"
                    value={this.state.cpfCnpj || ""}
                    onChange={(e) => this.handleCpfCnpjChange(e)}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <InputMask
                    name="telefone"
                    onChange={this.handleEditChange}
                    value={this.state.edit.telefone || ""}
                    mask="(99) 9999-9999" // Máscara de telefone
                  >
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        label="Telefone"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          style: {
                            height: 45,
                            padding: "0 5px",
                          },
                        }}
                        margin="normal"
                      />
                    )}
                  </InputMask>
                </div>
                <div className="col-md-6">
                  <InputMask
                    name="celular"
                    onChange={this.handleEditChange}
                    value={this.state.edit.celular || ""}
                    mask="(99) 9 9999-9999" // Máscara de telefone
                  >
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        label="Celular"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          style: {
                            height: 45,
                            padding: "0 5px",
                          },
                        }}
                        margin="normal"
                      />
                    )}
                  </InputMask>
                </div>
              </div>
              <div>
                <TextField
                  label="E-mail"
                  name="email"
                  onChange={this.handleEditChange}
                  value={this.state.edit.email || ""}
                  variant="outlined" // Pode ser "filled" ou "standard"
                  fullWidth // O campo ocupa toda a largura disponível
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />
              </div>

              <div className="row">
                <div className="col-md-6">
                  <InputMask
                    name="cep"
                    onChange={this.handleEditChange}
                    value={this.state.edit.cep || ""}
                    mask="99.999-999" // Máscara de telefone
                  >
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        label="CEP:"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          style: {
                            height: 45,
                            padding: "0 5px",
                          },
                        }}
                        margin="normal"
                      />
                    )}
                  </InputMask>
                </div>
                <div className="col-md-6">
                  <TextField
                    label="Endereço"
                    name="endereco"
                    onChange={this.handleEditChange}
                    value={this.state.edit.endereco || ""}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <TextField
                    label="Número"
                    name="numero"
                    onChange={this.handleEditChange}
                    value={this.state.edit.numero || ""}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    label="Bairro"
                    name="bairro"
                    onChange={this.handleEditChange}
                    value={this.state.edit.bairro || ""}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <TextField
                    label="Cidade"
                    name="cidade"
                    onChange={this.handleEditChange}
                    value={this.state.edit.cidade || ""}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    label="Estado"
                    name="estado"
                    onChange={this.handleEditChange}
                    value={this.state.edit.estado || ""}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />
                </div>
              </div>
            </form>
          </div>

          <div className="modal-fotter">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                var dados = this.state.edit;

                fetch(`${apiUrl}/editar-cliente`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    id: dados.id,
                    nome: dados.nome,
                    cnpj_cpf: this.state.cpfCnpj,
                    email: dados.email,
                    telefone: dados.telefone,
                    celular: dados.celular,
                    cep: dados.cep,
                    endereco: dados.endereco,
                    numero: dados.numero,
                    bairro: dados.bairro,
                    cidade: dados.cidade,
                    uf: dados.estado,
                  }), // corpo da requisição em formato JSON
                })
                  .then((response) => {
                    if (response.status == 200) {
                      response.json().then((data) => {
                        this.setState((prevState) => ({
                          items: prevState.items.map((item) =>
                            item.id === dados.id
                              ? {
                                  ...item,
                                  ...{
                                    nome: dados.nome,
                                    cnpj_cpf: this.state.cpfCnpj,
                                    email: dados.email,
                                    telefone: dados.telefone,
                                    celular: dados.celular,
                                    cep: dados.cep,
                                    endereco: dados.endereco,
                                    numero: dados.numero,
                                    bairro: dados.bairro,
                                    cidade: dados.cidade,
                                    uf: dados.estado,
                                  },
                                }
                              : item
                          ),
                          edit: {},
                          cpfCnpj: "",
                        }));

                        toast.success("Cliente alterado com sucesso!");
                      });
                    }
                  })
                  .catch((err) => {
                    toast.error("Falha na comunicação com o servidor!");
                  });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>
      </>
    );
  }
}

export default CadastroClientes;
