import React, { Component } from "react";

import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faCog,
  faPen,
  faSave,
  faStore,
  faTrash,
  faUser,
  fawhatsapp,
} from "@fortawesome/free-solid-svg-icons";
import InputMask from "react-input-mask";
import { Link } from "react-router-dom";
import { apiUrl } from "../../../../comps/apiUrl";
import withLocation from "../../../../comps/withLocation";
import { StaticDialog } from "react-st-modal";
class StoreSellers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      store: [],
      sellers: [],
      add: false,
      edit: {},
      trash: {},
      formData: {
        name: "",
        cpf: "",
        rg: "",
        birth: "",
        celular: "",
        telefone: "",
        email: "",
        status: "",
      },
    };
  }

  componentDidMount() {
    document.title = "Carregando...";
    fetch(`${apiUrl}/store/sellers/${this.props.params.token}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        if (response.status == 404) {
          this.setState({
            loader: false,
          });
        }

        if (response.status == 200) {
          response.json().then((data) => {
            document.title = `${data.store.name} > Funcionários`;
            this.setState({
              store: data.store,
              sellers: data.sellers,
              loader: false,
            });
          });
        }
      })
      .catch((error) => {
        toast.error("Ocorreu um erro ao carregar as maquinas");
      });
  }

  handleInputChange = (e) => {
    const { id, value } = e.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [id]: value,
      },
    }));
  };

  handleInputEditChange = (e) => {
    const { id, value } = e.target;
    this.setState((prevState) => ({
      edit: {
        ...prevState.edit,
        [id]: value,
      },
    }));
  };

  handleSave = () => {
    const { formData, store } = this.state;

    if (formData.role == "" || formData.role == null) {
      toast.warn("Selecione a permissão do funcionário");
      return;
    }

    if (isNaN(new Date(formData.birth).getTime())) {
      toast.warn("Data de nascimento inválida");
      return;
    }

    fetch(`${apiUrl}/store/${this.props.params.token}/add/seller`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({
        store,
        form: formData,
      }),
    })
      .then((response) => {
        if (response.status == 200) {
          toast.success("Funcionário cadastrado com sucesso.");
          this.setState((prevState) => ({
            sellers: [{ ...formData }, ...prevState.sellers],
            add: false,
          }));
        }
      })
      .catch(() => {
        toast.error("Erro ao salvar funcionário.");
      });
  };

  handleEditSave = () => {
    const { edit, store } = this.state;
    console.log(edit);
    if (edit.role == "" || edit.role == null) {
      toast.warn("Selecione a permissão do funcionário");
      return;
    }

    if (isNaN(new Date(edit.birth).getTime())) {
      toast.warn("Data de nascimento inválida");
      return;
    }

    console.log(edit);
    fetch(`${apiUrl}/store/${this.props.params.token}/edit/seller`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({
        store,
        form: edit,
      }),
    })
      .then((response) => {
        if (response.status == 200) {
          toast.success("Funcionário editado com sucesso.");
          this.setState((prevState) => ({
            sellers: prevState.sellers.map((item) =>
              item.id === edit.id
                ? {
                    ...item,
                    ...{
                      name: edit.name,
                      user: edit.user,
                      pass: edit.pass,
                      role: edit.role,
                      cpf: edit.cpf,
                      rg: edit.rg,
                      birth: edit.birth,
                      celular: edit.celular,
                      telefone: edit.telefone,
                      email: edit.email,
                      status: edit.status,
                    },
                  }
                : item
            ),
            edit: {},
          }));
        }
      })
      .catch(() => {
        toast.error("Erro ao salvar funcionário.");
      });
  };

  render() {
    if (this.state.loader) {
      return (
        <div class="area-loader">
          <div class="loader"></div>
        </div>
      );
    }

    return (
      <div className="content d-flex justify-content-center">
        <div className="col-8 mt-5">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <strong>
                {this.state.store.name} <FontAwesomeIcon icon={faAngleRight} />{" "}
                Funcionários
              </strong>
              <button
                className="btn btn-primary"
                onClick={() => {
                  this.setState({ add: true });
                }}
              >
                <FontAwesomeIcon icon={faUser} /> Adicionar Funcionário
              </button>
            </div>
            <div className="card-body">
              {this.state.sellers.length === 0 ? (
                <div className="d-flex justify-content-center align-items-center">
                  <span>Nenhum funcionário cadastrado</span>
                </div>
              ) : (
                <table className="table table-striped table-bordered arvore-l mt-2">
                  <thead className="thead-dark">
                    <tr>
                      <th>ID</th>
                      <th>Nome</th>
                      <th>Cpf</th>
                      <th>Celular</th>

                      <th>Status</th>
                      <th>Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.sellers.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.cpf}</td>
                        <td>{item.celular}</td>

                        <td>{item.status}</td>
                        <td className="btns">
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              this.setState({ edit: item });
                            }}
                          >
                            <FontAwesomeIcon icon={faPen} />
                          </button>
                          <button
                            className="btn btn-danger"
                            onClick={() => {
                              this.setState({ trash: item });
                            }}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        <StaticDialog
          isOpen={this.state.add}
          title="Adicionar funcionário"
          onAfterClose={() => {
            this.setState({ add: false });
          }}
        >
          <div className="content">
            <form>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Nome do funcionário(a)
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Digite:"
                  value={this.state.formData.name}
                  onChange={this.handleInputChange}
                />
              </div>

              <div className="row mt-3 mb-3">
                <div className="col-md-4">
                  <label htmlFor="cpf" className="form-label">
                    Usuário
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="user"
                    placeholder="Usuário:"
                    value={this.state.formData.user || ""}
                    onChange={this.handleInputChange}
                  />
                </div>

                <div className="col-md-4">
                  <label htmlFor="cpf" className="form-label">
                    Senha
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="pass"
                    placeholder="Senha:"
                    value={this.state.formData.pass || ""}
                    onChange={this.handleInputChange}
                  />
                </div>

                <div className="col-md-4">
                  <label htmlFor="role" className="form-label">
                    Permissão
                  </label>
                  <select
                    className="form-select"
                    id="role"
                    name="role"
                    onChange={this.handleInputChange || ""}
                  >
                    <option value="">Selecione:</option>
                    <option value="user">Padrão</option>
                    <option value="master">Administrador</option>
                  </select>
                </div>
              </div>

              <div className="row mt-3 mb-3">
                <div className="col-md-6">
                  <label htmlFor="cpf" className="form-label">
                    CPF
                  </label>
                  <InputMask
                    mask="999.999.999-99"
                    type="text"
                    className="form-control"
                    id="cpf"
                    placeholder="Digite:"
                    value={this.state.formData.cpf}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="rg" className="form-label">
                    RG
                  </label>
                  <InputMask
                    mask="99.999.999-*"
                    type="text"
                    className="form-control"
                    id="rg"
                    placeholder="Digite:"
                    value={this.state.formData.rg}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="row mt-3 mb-3">
                <div className="col-md-6">
                  <label htmlFor="birth" className="form-label">
                    Data de nascimento
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="birth"
                    value={this.state.formData.birth}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="celular" className="form-label">
                    Celular
                  </label>
                  <InputMask
                    mask="(99) 9 9999-9999"
                    type="text"
                    className="form-control"
                    id="celular"
                    placeholder="Digite:"
                    value={this.state.formData.celular}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>

              <div>
                <label htmlFor="status" className="form-label">
                  Status
                </label>
                <select
                  className="form-select"
                  id="status"
                  value={this.state.formData.status}
                  onChange={this.handleInputChange}
                >
                  <option value="">Selecione</option>
                  <option value="ativo">Ativo</option>
                  <option value="inativo">Inativo</option>
                  <option value="experiencia">Em Período de Experiência</option>
                  <option value="afastado">Afastado</option>
                  <option value="demitido">Demitido</option>
                  <option value="desligado">Desligado</option>
                  <option value="aposentado">Aposentado</option>
                  <option value="treinamento">Em Treinamento</option>
                  <option value="ferias">De Férias</option>
                  <option value="suspenso">Suspenso</option>
                  <option value="negociacao">Em Negociação</option>
                  <option value="temporario">Temporário</option>
                  <option value="admissao">Em Processo de Admissão</option>
                  <option value="desligamento">
                    Em Processo de Desligamento
                  </option>
                </select>
              </div>
            </form>
          </div>
          <div className="modal-fotter">
            <button className="btn btn-primary" onClick={this.handleSave}>
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.edit).length > 0}
          title="Editar funcionário"
          onAfterClose={() => {
            this.setState({ edit: {} });
          }}
        >
          <div className="content">
            <form>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Nome do funcionário(a)
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Digite:"
                  value={this.state.edit.name}
                  onChange={this.handleInputEditChange}
                />
              </div>
              <div className="row mt-3 mb-3">
                <div className="col-md-4">
                  <label htmlFor="cpf" className="form-label">
                    Usuário
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="user"
                    placeholder="Usuário:"
                    value={this.state.edit.user || ""}
                    onChange={this.handleInputEditChange}
                  />
                </div>

                <div className="col-md-4">
                  <label htmlFor="cpf" className="form-label">
                    Senha
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="pass"
                    placeholder="Senha:"
                    value={this.state.edit.pass || ""}
                    onChange={this.handleInputEditChange}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="role" className="form-label">
                    Permissão
                  </label>
                  <select
                    id="role"
                    className="form-select"
                    name="role"
                    value={this.state.edit.role || ""}
                    onChange={this.handleInputEditChange}
                  >
                    <option value="">Selecione:</option>
                    <option value="user">Padrão</option>
                    <option value="master">Administrador</option>
                  </select>
                </div>
              </div>

              <div className="row mt-3 mb-3">
                <div className="col-md-6">
                  <label htmlFor="cpf" className="form-label">
                    CPF
                  </label>
                  <InputMask
                    mask="999.999.999-99"
                    type="text"
                    className="form-control"
                    id="cpf"
                    placeholder="Digite:"
                    value={this.state.edit.cpf}
                    onChange={this.handleInputEditChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="rg" className="form-label">
                    RG
                  </label>
                  <InputMask
                    mask="99.999.999-*"
                    type="text"
                    className="form-control"
                    id="rg"
                    placeholder="Digite:"
                    value={this.state.edit.rg}
                    onChange={this.handleInputEditChange}
                  />
                </div>
              </div>
              <div className="row mt-3 mb-3">
                <div className="col-md-6">
                  <label htmlFor="birth" className="form-label">
                    Data de nascimento
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="birth"
                    value={this.state.edit.birth}
                    onChange={this.handleInputEditChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="celular" className="form-label">
                    Celular
                  </label>
                  <InputMask
                    mask="(99) 9 9999-9999"
                    type="text"
                    className="form-control"
                    id="celular"
                    placeholder="Digite:"
                    value={this.state.edit.celular}
                    onChange={this.handleInputEditChange}
                  />
                </div>
              </div>

              <div>
                <label htmlFor="status" className="form-label">
                  Status
                </label>
                <select
                  className="form-select"
                  id="status"
                  value={this.state.edit.status}
                  onChange={this.handleInputEditChange}
                >
                  <option value="">Selecione</option>
                  <option value="ativo">Ativo</option>
                  <option value="inativo">Inativo</option>
                  <option value="experiencia">Em Período de Experiência</option>
                  <option value="afastado">Afastado</option>
                  <option value="demitido">Demitido</option>
                  <option value="desligado">Desligado</option>
                  <option value="aposentado">Aposentado</option>
                  <option value="treinamento">Em Treinamento</option>
                  <option value="ferias">De Férias</option>
                  <option value="suspenso">Suspenso</option>
                  <option value="negociacao">Em Negociação</option>
                  <option value="temporario">Temporário</option>
                  <option value="admissao">Em Processo de Admissão</option>
                  <option value="desligamento">
                    Em Processo de Desligamento
                  </option>
                </select>
              </div>
            </form>
          </div>
          <div className="modal-fotter">
            <button className="btn btn-primary" onClick={this.handleEditSave}>
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.trash).length > 0}
          title="Tem certeza de que deseja continuar?"
          onAfterClose={() => {
            this.setState({ trash: false });
          }}
        >
          <div className="content">
            <span>
              Se você confirmar a exclusão, não será possível recuperar os dados
              posteriormente.
            </span>
          </div>
          <div className="modal-fotter">
            <button
              className="btn btn-danger"
              onClick={(e) => {
                const { trash } = this.state;
                e.target.classList.add("load");

                fetch(
                  `${apiUrl}/store/${this.props.params.token}/delete/seller`,
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      authorization: localStorage.getItem("token"),
                    },
                    body: JSON.stringify({
                      seller: trash,
                    }),
                  }
                )
                  .then((response) => {
                    e.target.classList.remove("load");
                    if (response.status == 200) {
                      this.setState((prevState) => ({
                        sellers: prevState.sellers.filter(
                          (item) => item.id !== trash.id
                        ),
                        trash: {},
                      }));
                      toast.success("Funcionario excluido com sucesso.");
                    }
                  })
                  .catch(() => {
                    e.target.classList.remove("load");
                    toast.error("Erro ao salvar funcionário.");
                  });
              }}
            >
              <FontAwesomeIcon icon={faTrash} /> Excluir
            </button>
          </div>
        </StaticDialog>
      </div>
    );
  }
}

export default withLocation(StoreSellers);
