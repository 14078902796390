import React, { Component } from "react";
import { Link } from "react-router-dom";
import atencao from "../../img/atencao.svg";
import logo from "../../img/logo.png";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCalendar,
  faClipboard,
  faCoffee,
  faCog,
  faEnvelope,
  faEye,
  faFile,
  faFileAlt,
  faFileExcel,
  faFileInvoice,
  faFileText,
  faHome,
  faIdCard,
  faMapMarkerAlt,
  faMobileAlt,
  faPen,
  faPhone,
  faPrint,
  faSave,
  faSearch,
  faTrash,
  faUser,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { StaticDialog, useDialog } from "react-st-modal";
import InputMask from "react-input-mask";
import { apiUrl } from "../../comps/apiUrl";
import { toast } from "react-toastify";

class CurrentLease extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      add: false,
      dados: {},
      items: [],
      currentPage: 1,
      totalItems: 0,
      itemsPerPage: 15,
      comprovante: {},
      edit: {},
      trash: {},
      viewProducts: {},
      comprovante: {},
    };
  }

  componentDidMount() {
    document.title = "Histórico de locações";
    this.fetchData(this.state.currentPage);
  }

  fetchData(page) {
    fetch(
      `${apiUrl}/current-lease?page=${page}&limit=${this.state.itemsPerPage}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    ).then((response) => {
      if (response.status === 510) {
        localStorage.clear();
        window.location.href = "/";
      }

      response.json().then((data) => {
        const totalItems = data.totalItems; // Simulado, substitua pelo valor real do backend.
        const totalPages = Math.ceil(totalItems / this.state.itemsPerPage);

        this.setState({
          items: data.items,
          currentPage: page,
          totalPages,
          loader: false,
        });
      });
    });
  }

  handlePageClick = (page) => {
    this.fetchData(page);
  };
  handleNextPage = () => {
    this.fetchData(this.state.currentPage + 1);
  };
  formatarDataBR(dataISO) {
    // Converte a string ISO em um objeto Date
    const data = new Date(dataISO);

    // Extrai as partes da data
    const dia = String(data.getDate()).padStart(2, "0");
    const mes = String(data.getMonth() + 1).padStart(2, "0"); // Meses começam do zero
    const ano = data.getFullYear();

    // Extrai as partes do horário
    const horas = String(data.getHours()).padStart(2, "0");
    const minutos = String(data.getMinutes()).padStart(2, "0");
    const secounds = String(data.getSeconds()).padStart(2, "0");

    // Retorna no formato brasileiro
    return `${dia}/${mes}/${ano} ${horas}:${minutos}:${secounds}`;
  }
  handlePreviousPage = () => {
    if (this.state.currentPage > 1) {
      this.fetchData(this.state.currentPage - 1);
    }
  };

  renderPagination() {
    const { currentPage, totalPages } = this.state;

    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => this.handlePageClick(i)}
          style={{
            backgroundColor: i === currentPage ? "#007bff" : "#fff",
            color: i === currentPage ? "#fff" : "#000",
            border: "1px solid #ddd",
            cursor: "pointer",
          }}
        >
          {i}
        </button>
      );
    }

    return (
      <div>
        <button
          className="btn btn-ligth"
          onClick={() => this.handlePageClick(currentPage - 1)}
          disabled={currentPage === 1}
          style={{
            cursor: currentPage === 1 ? "not-allowed" : "pointer",
          }}
        >
          &lt;
        </button>

        {pages}

        <button
          onClick={() => this.handlePageClick(currentPage + 1)}
          className="btn btn-ligth"
          disabled={currentPage === totalPages}
          style={{
            cursor: currentPage === totalPages ? "not-allowed" : "pointer",
          }}
        >
          &gt;
        </button>
      </div>
    );
  }

  handleChange = (event) => {
    const { id, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      dados: {
        ...prevState.dados, // Mantém os outros campos do objeto `dados`
        [id]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };

  handleEdit = (event) => {
    const { id, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      edit: {
        ...prevState.edit, // Mantém os outros campos do objeto `dados`
        [id]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };
  formatDate(dateString) {
    var e = dateString.split("-");
    var dateString = e[2] + "/" + e[1] + "/" + e[0];
    return dateString;
  }
  handleCpfCnpjChange = (e) => {
    function cnpj(v) {
      v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
      v = v.replace(/^(\d{2})(\d)/, "$1.$2"); //Coloca ponto entre o segundo e o terceiro dígitos
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3"); //Coloca ponto entre o quinto e o sexto dígitos
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2"); //Coloca uma barra entre o oitavo e o nono dígitos
      v = v.replace(/(\d{4})(\d)/, "$1-$2"); //Coloca um hífen depois do bloco de quatro dígitos
      return v;
    }

    function cpf(v) {
      v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
      v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
      v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
      //de novo (para o segundo bloco de números)
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); //Coloca um hífen entre o terceiro e o quarto dígitos
      return v;
    }

    if (e.target.value.length > 18) return;
    var value = e.target.value;
    if (e.target.value.length <= 14) {
      this.setState((prevState) => ({
        dados: {
          ...prevState.dados, // Mantém os outros campos do objeto `dados`
          ["doc"]: cpf(value), // Atualiza apenas o campo correspondente
        },
      }));
    } else {
      this.setState((prevState) => ({
        dados: {
          ...prevState.dados, // Mantém os outros campos do objeto `dados`
          ["doc"]: cnpj(value), // Atualiza apenas o campo correspondente
        },
      }));
    }
  };

  render() {
    if (this.state.loader) {
      return (
        <div class="area-loader">
          <div class="loader"></div>
        </div>
      );
    }
    var invoicingType = {
      1: "Diária",
      2: "Semanal",
      3: "Quizenal",
      4: "Mensal",
      5: "Anual",
    };

    var priceType = {
      1: "day_price",
      2: "weekly_price",
      3: "biweekly_price",
      4: "monthly_price",
      5: "annual_price",
    };
    return (
      <>
        <div class="content">
          <div class="card">
            <div class="card-header d-flex align-items-center justify-content-between">
              <strong>Locações</strong>
            </div>
            <div class="card-body">
              {this.state.items.length > 0 ? (
                <>
                  <div class="mb-3 d-flex justify-content-center">
                    <div class="input-group w-50">
                      <input
                        type="text"
                        class="form-control"
                        id="searchInput"
                        onChange={(e) => {
                          this.setState({ search: e.target.value });
                        }}
                        placeholder="Pesquisar..."
                      />
                      <button
                        class="btn btn-primary"
                        onClick={(e) => {
                          console.log("aaaa");
                          e.target.classList.add("load");
                          var search = this.state.search;
                          fetch(`${apiUrl}/buscar-cliente?s=${search}`, {
                            method: "GET",
                            headers: {
                              "Content-Type": "application/json",
                              authorization: localStorage.getItem("token"),
                            },
                          })
                            .then((response) => response.json())
                            .then((data) => {
                              e.target.classList.remove("load");
                            });
                        }}
                      >
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </div>
                  </div>
                  <table class="table table-striped table-bordered arvore-l">
                    <thead class="thead-dark">
                      <tr>
                        <th>ID do Cliente</th>
                        <th>Funcionário (a)</th>
                        <th>Cliente</th>
                        <th>Faturamento</th>
                        <th>Valor Locação</th>
                        <th>Locação</th>
                        <th>Vencimento</th>
                        <th>Status</th>

                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.items.map((item, index) => (
                        <>
                          <tr>
                            <td>{item.id}</td>
                            <td>{item.seller_name}</td>
                            <td>
                              <Link
                                to={`/cadastro-clientes/view/${item.client_id}`}
                                style={{ textDecoration: "none" }}
                              >
                                {item.client_name}
                              </Link>
                            </td>
                            <td>{invoicingType[item.billing]}</td>
                            <td>
                              {item.location_value.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </td>

                            <td>{this.formatarDataBR(item.start_day)}</td>
                            <td>
                              {item.renew.length > 0 ? (
                                <>
                                  <s>{this.formatarDataBR(item.end_day)}</s> -{" "}
                                  {this.formatarDataBR(item.renew[0].end_day)}
                                </>
                              ) : (
                                <>{this.formatarDataBR(item.end_day)}</>
                              )}
                            </td>
                            <td>
                              <span
                                class="badge"
                                style={{
                                  background: item.order.bg,
                                  color: item.order.color,
                                }}
                              >
                                {item.order.message}
                              </span>
                            </td>
                            <td>
                              <div className="d-flex justify-content-center align-items-center">
                                <Link
                                  to={`/lease/renew-location/${item.token}`}
                                  className="btn btn-primary"
                                >
                                  <FontAwesomeIcon icon={faCalendar} />
                                </Link>
                                <button
                                  className="btn"
                                  style={{
                                    marginLeft: "10px",
                                    background: "#8A2BE2",
                                  }}
                                  onClick={() => {
                                    this.props.navigate(
                                      `/lease/contract/${item.token}`
                                    );
                                  }}
                                >
                                  <FontAwesomeIcon
                                    color="#fff"
                                    icon={faPrint}
                                  />
                                </button>
                                <button
                                  className="btn btn-success"
                                  style={{ marginLeft: "10px" }}
                                  onClick={() => {
                                    this.setState({ comprovante: item });
                                  }}
                                >
                                  <FontAwesomeIcon icon={faFile} />
                                </button>
                              </div>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <>Não há resultados</>
              )}
            </div>

            <div
              className="card-footer"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {this.renderPagination()}
            </div>
          </div>
        </div>

        <StaticDialog
          isOpen={Object.keys(this.state.comprovante).length > 0}
          title="Informações de Devolução"
          onAfterClose={(result) => {
            this.setState({ comprovante: {} });
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();

              var form = e.target;
              if (this.state.comprovante.state == undefined) {
                toast.warn("Por favor, selecione o estado da devolução!");
                return;
              }

              form[3].classList.add("load");
              fetch(
                `${apiUrl}/finalizar-locacao/${this.state.comprovante.token}`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.getItem("token"),
                  },
                  body: JSON.stringify({
                    client: this.state.comprovante.client_id,
                    obs_end: this.state.comprovante.obs,
                    state: this.state.comprovante.state,
                    desc: this.state.comprovante.desc,
                  }),
                }
              )
                .then((response) => {
                  if (response.status == 200) {
                    form[3].classList.remove("load");
                    this.setState((prevState) => ({
                      items: prevState.items.filter(
                        (item) => item.token !== this.state.comprovante.token
                      ),
                      comprovante: {},
                    }));

                    toast.success("Locação finalizada com sucesso!");
                  }
                })
                .catch((error) => {
                  form[3].classList.remove("load");
                  toast.error("Erro ao finalizar locação!");
                });
            }}
          >
            <div className="content">
              <div class="mb-3">
                <label for="nome-cliente" class="form-label">
                  Nome do Cliente
                </label>
                <input
                  type="text"
                  id="nome-cliente"
                  class="form-control"
                  value={this.state.comprovante.client_name}
                  disabled
                />
              </div>
              <div className="mb-3">
                <label class="form-label">Estado do Equipamento</label>
                <select
                  className="form-control"
                  onChange={(e) => {
                    var v = e.target.value;
                    if (v == "") {
                      return;
                    }

                    this.setState((prevState) => ({
                      comprovante: {
                        ...prevState.comprovante,
                        ["state"]: e.target.value, // Atualiza apenas o campo especificado
                      },
                    }));
                  }}
                  value={this.state.comprovante.state || ""}
                >
                  <option value="">Selecione:</option>
                  <option value="1">Em Ordem</option>
                  <option value="2">Avariado</option>
                </select>
              </div>
              <div>
                <textarea
                  class="form-control"
                  placeholder="Observação"
                  value={this.state.comprovante.desc || ""}
                  onChange={(e) => {
                    this.setState((prevState) => ({
                      comprovante: {
                        ...prevState.comprovante,
                        ["desc"]: e.target.value, // Atualiza apenas o campo especificado
                      },
                    }));
                  }}
                  style={{ height: "100px" }}
                />
              </div>
            </div>
            <div className="modal-fotter">
              <button type="submit" className="btn btn-primary">
                <FontAwesomeIcon icon={faSave} /> Finalizar Locação
              </button>
            </div>
          </form>
        </StaticDialog>
      </>
    );
  }
}

export default CurrentLease;
