import React, { Component } from "react";

import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faPen,
  faSignIn,
  faStore,
  fawhatsapp,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { apiUrl } from "../../../comps/apiUrl";
import withLocation from "../../../comps/withLocation";
class SelectStore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      data: {},
      error: false,
    };
  }

  componentDidMount() {
    document.title = "Carregando...";
    fetch(`${apiUrl}/store/select/${this.props.params.token}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        if (response.status == 404) {
          this.setState({
            loader: false,
          });
        }

        if (response.status == 200) {
          response.json().then((data) => {
            document.title = data.name;
            this.setState({
              data,

              loader: false,
            });
          });
        }
      })
      .catch((error) => {
        toast.error("Ocorreu um erro ao carregar as maquinas");
      });
  }

  formatDate(dateString) {
    var e = dateString.split("-");
    var dateString = e[2] + "/" + e[1] + "/" + e[0];
    return dateString;
  }

  render() {
    if (this.state.loader) {
      return (
        <div class="area-loader">
          <div class="loader"></div>
        </div>
      );
    }

    return (
      <div class="content d-flex justify-content-center">
        <div class="col-8 mt-5">
          <div class="card">
            <div class="card-header d-flex justify-content-between align-items-center">
              <strong>{this.state.data.name}</strong>
            </div>
            <div class="card-body">
              <table class="table table-striped table-bordered arvore-l mt-2">
                <thead class="thead-dark">
                  <tr>
                    <th>Descrição</th>
                    <th>Ação</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Funcionários</td>
                    <td>
                      <Link
                        className="btn btn-success"
                        to={`/stores/${this.props.params.token}/sellers`}
                      >
                        <FontAwesomeIcon icon={faSignIn} /> Acessar
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withLocation(SelectStore);
