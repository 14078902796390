import React, { Component } from "react";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPen,
  faPlus,
  faSave,
  faSign,
  faSignIn,
  faStore,
  faTrash,
  fawhatsapp,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { apiUrl } from "../../comps/apiUrl";
import { StaticDialog } from "react-st-modal";
class Stores extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      data: [],
      addStore: false,
      addAuth: {},
      EditAuth: {},
      edit: {},
      trash: {},
      error: false,
      store: {
        name: "",
        user: "",
        pass: "",
        cnpj: "",
        address: "",
        neighborhood: "",
        city: "",
        state: "",
        mobile: "",
        phone: "",
        email: "",
      },
    };
  }

  componentDidMount() {
    document.title = "Lojas";
    fetch(`${apiUrl}/stores`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        if (response.status == 404) {
          this.setState({
            loader: false,
          });
        }

        if (response.status == 200) {
          response.json().then((data) => {
            this.setState({
              data: data,
              loader: false,
            });
          });
        }
      })
      .catch((error) => {
        toast.error("Ocorreu um erro ao carregar as maquinas");
      });
  }

  formatDate(dateString) {
    var e = dateString.split("-");
    var dateString = e[2] + "/" + e[1] + "/" + e[0];
    return dateString;
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      store: {
        ...prevState.store,
        [name]: value,
      },
    }));
  };

  handleEditChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      edit: {
        ...prevState.edit,
        [name]: value,
      },
    }));
  };

  handleAuthSubmit = (e) => {
    var auth = this.state.store;
    var { id } = this.state.addAuth;

    e.preventDefault();
    fetch(`${apiUrl}/store/auth/new`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({
        id: id,
        user: auth.user,
        pass: auth.pass,
      }),
    })
      .then((response) => {
        if (response.status == 200) {
          response.json().then((data) => {
            this.setState((prevState) => ({
              data: prevState.data.map((item) =>
                item.id === id
                  ? {
                      ...item,
                      ...{
                        auth: [{ user: auth.user }],
                      },
                    }
                  : item
              ),
              addAuth: {},
            }));

            toast.success("Autenticação cadastrada com sucesso");
          });
        }
      })
      .catch((error) => {
        toast.error("Ocorreu um erro ao cadastrar a loja");
      });
  };

  handleEditAuthSubmit = (e) => {
    var edit = this.state.EditAuth;
    e.preventDefault();

    fetch(`${apiUrl}/store/auth/edit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({
        id: edit.auth.id,
        user: edit.auth.user,
        pass: edit.auth.npass,
      }),
    })
      .then((response) => {
        if (response.status == 200) {
          response.json().then((data) => {
            this.setState((prevState) => ({
              data: prevState.data.map((item) =>
                item.id === edit.id
                  ? {
                      ...item,
                      ...{
                        auth: {
                          ...prevState.data.auth,
                          user: edit.auth.user,
                        },
                      },
                    }
                  : item
              ),
              EditAuth: {},
            }));
            toast.success("Autenticação editada com sucesso");
          });
        }
      })
      .catch((error) => {
        toast.error("Ocorreu um erro ao cadastrar a loja");
      });
  };

  handleSubmit = (e) => {
    var store = this.state.store;
    e.preventDefault();
    fetch(`${apiUrl}/store/new`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify(store),
    })
      .then((response) => {
        if (response.status == 200) {
          response.json().then((data) => {
            toast.success("Loja cadastrada com sucesso");
            this.setState((prevState) => ({
              data: [
                {
                  auth: [],
                  token: data.token,
                  name: store.name,
                  user: store.user,
                  pass: store.pass,
                  cnpj: store.cnpj,
                  address: store.address,
                  neighborhood: store.neighborhood,
                  city: store.city,
                  state: store.state,
                  mobile: store.mobile,
                  phone: store.phone,
                  email: store.email,
                },
                ...prevState.data,
              ],
              addStore: false,
            }));
          });
        }
      })
      .catch((error) => {
        toast.error("Ocorreu um erro ao cadastrar a loja");
      });
  };

  handleEditSubmit = (e) => {
    e.preventDefault();
    var edit = this.state.edit;
    fetch(`${apiUrl}/store/edit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify(edit),
    })
      .then((response) => {
        if (response.status == 200) {
          toast.success("Loja editada com sucesso");
          this.setState((prevState) => ({
            data: prevState.data.map((item) =>
              item.id === edit.id
                ? {
                    ...item,
                    ...{
                      name: edit.name,
                      user: edit.user,
                      pass: edit.pass,
                      cnpj: edit.cnpj,
                      address: edit.address,
                      neighborhood: edit.neighborhood,
                      city: edit.city,
                      state: edit.state,
                      mobile: edit.mobile,
                      phone: edit.phone,
                      email: edit.email,
                    },
                  }
                : item
            ),
            edit: {},
          }));
        }
      })
      .catch((error) => {
        toast.error("Ocorreu um erro ao editar a loja");
      });
  };

  render() {
    if (this.state.loader) {
      return (
        <div class="area-loader">
          <div class="loader"></div>
        </div>
      );
    }

    const {
      name,
      user,
      pass,
      cpfCnpj,
      address,
      neighborhood,
      city,
      state,
      mobile,
      phone,
      email,
    } = this.state.store;
    var edit = this.state.edit;

    return (
      <div class="content d-flex justify-content-center">
        <div class="col-8 mt-5">
          <div class="card">
            <div class="card-header d-flex justify-content-between align-items-center">
              <strong>Lojas</strong>
            </div>
            <div class="card-body">
              {this.state.data.length == 0 ? (
                <p>Não há lojas cadastradas</p>
              ) : (
                <table class="table table-striped table-bordered arvore-l mt-2">
                  <thead class="thead-dark">
                    <tr>
                      <th>Unidade</th>
                      <th>CNPJ</th>
                      <th>Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.map((item) => (
                      <tr>
                        <td>{item.name}</td>
                        <td>{item.cnpj}</td>

                        <td class="btns">
                          <Link
                            to={`/stores/${item.token}`}
                            className="btn btn-success"
                          >
                            <FontAwesomeIcon icon={faSignIn} /> Acessar
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <StaticDialog
          isOpen={Object.keys(this.state.addAuth).length > 0}
          title="Adicionar autenticação"
          onAfterClose={(result) => {
            this.setState({ addAuth: {} });
            // do something with dialog result
          }}
        >
          <div className="content">
            <div className="row">
              <div className="col-12">
                <form onSubmit={this.handleAuthSubmit}>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Usuário:
                    </label>
                    <input
                      type="text"
                      id="user"
                      name="user"
                      className="form-control"
                      value={user}
                      onChange={this.handleChange}
                      placeholder="Digite:"
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Senha:
                    </label>
                    <input
                      type="password"
                      id="pass"
                      name="pass"
                      className="form-control"
                      value={pass}
                      onChange={this.handleChange}
                      placeholder="Digite:"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="modal-fotter">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={this.handleAuthSubmit}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.EditAuth).length > 0}
          title="Editar autenticação"
          onAfterClose={(result) => {
            this.setState({ EditAuth: {} });
            // do something with dialog result
          }}
        >
          <div className="content">
            <div className="row">
              <div className="col-12">
                <form onSubmit={this.handleEditAuthSubmit}>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Usuário:
                    </label>
                    <input
                      type="text"
                      id="user"
                      name="user"
                      className="form-control"
                      value={
                        this.state.EditAuth.auth != undefined &&
                        this.state.EditAuth.auth.user
                      }
                      onChange={(e) => {
                        this.setState((prevState) => ({
                          EditAuth: {
                            ...prevState.EditAuth,
                            auth: {
                              ...prevState.EditAuth.auth,
                              user: e.target.value, // Substitua pelo novo valor desejado
                            },
                          },
                        }));
                      }}
                      placeholder="Digite:"
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Nova senha:
                    </label>
                    <input
                      type="password"
                      id="pass"
                      name="pass"
                      className="form-control"
                      value={
                        this.state.EditAuth.auth != undefined
                          ? this.state.EditAuth.auth.npass
                          : ""
                      }
                      onChange={(e) => {
                        this.setState((prevState) => ({
                          EditAuth: {
                            ...prevState.EditAuth,
                            auth: {
                              ...prevState.EditAuth.auth,
                              npass: e.target.value, // Substitua pelo novo valor desejado
                            },
                          },
                        }));
                      }}
                      placeholder="Digite:"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="modal-fotter">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={this.handleEditAuthSubmit}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={this.state.addStore}
          title="Adicionar loja"
          onAfterClose={(result) => {
            this.setState({ addStore: false });
            // do something with dialog result
          }}
        >
          <div className="content">
            <form onSubmit={this.handleSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Nome
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  value={name}
                  onChange={this.handleChange}
                  placeholder="Digite o nome"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="cpfCnpj" className="form-label">
                  CNPJ
                </label>
                <InputMask
                  mask="99.999.999/9999-99"
                  id="cnpj"
                  name="cnpj"
                  className="form-control"
                  value={cpfCnpj}
                  onChange={this.handleChange}
                  placeholder="Digite o CNPJ"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="address" className="form-label">
                  Endereço
                </label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  className="form-control"
                  value={address}
                  onChange={this.handleChange}
                  placeholder="Digite o endereço"
                />
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="neighborhood" className="form-label">
                    Bairro
                  </label>
                  <input
                    type="text"
                    id="neighborhood"
                    name="neighborhood"
                    className="form-control"
                    value={neighborhood}
                    onChange={this.handleChange}
                    placeholder="Digite o bairro"
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="city" className="form-label">
                    Cidade
                  </label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    className="form-control"
                    value={city}
                    onChange={this.handleChange}
                    placeholder="Digite a cidade"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="state" className="form-label">
                    UF
                  </label>
                  <input
                    type="text"
                    id="state"
                    name="state"
                    className="form-control"
                    value={state}
                    onChange={this.handleChange}
                    placeholder="UF"
                    maxLength={2}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="mobile" className="form-label">
                    Celular
                  </label>
                  <InputMask
                    mask="(99) 9 9999-9999"
                    id="mobile"
                    name="mobile"
                    className="form-control"
                    value={mobile}
                    onChange={this.handleChange}
                    placeholder="Digite o celular"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="phone" className="form-label">
                    Fone Comercial
                  </label>
                  <InputMask
                    mask="(99) 9999-9999"
                    id="phone"
                    name="phone"
                    className="form-control"
                    value={phone}
                    onChange={this.handleChange}
                    placeholder="Digite o telefone"
                  />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  value={email}
                  onChange={this.handleChange}
                  placeholder="Digite o email"
                />
              </div>
            </form>
          </div>
          <div className="modal-fotter">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={this.handleSubmit}
            >
              <FontAwesomeIcon icon={faSave} /> Adicionar
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.edit).length > 0}
          title="Editar loja"
          onAfterClose={(result) => {
            this.setState({ edit: {} });
            // do something with dialog result
          }}
        >
          <div className="content">
            <form onSubmit={this.handleEditSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Nome
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  value={edit.name}
                  onChange={this.handleEditChange}
                  placeholder="Digite o nome"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="cpfCnpj" className="form-label">
                  CNPJ
                </label>
                <InputMask
                  mask="99.999.999/9999-99"
                  id="cnpj"
                  name="cnpj"
                  className="form-control"
                  value={edit.cnpj}
                  onChange={this.handleEditChange}
                  placeholder="Digite o CNPJ"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="address" className="form-label">
                  Endereço
                </label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  className="form-control"
                  value={edit.address}
                  onChange={this.handleEditChange}
                  placeholder="Digite o endereço"
                />
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="neighborhood" className="form-label">
                    Bairro
                  </label>
                  <input
                    type="text"
                    id="neighborhood"
                    name="neighborhood"
                    className="form-control"
                    value={edit.neighborhood}
                    onChange={this.handleEditChange}
                    placeholder="Digite o bairro"
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="city" className="form-label">
                    Cidade
                  </label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    className="form-control"
                    value={edit.city}
                    onChange={this.handleEditChange}
                    placeholder="Digite a cidade"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="state" className="form-label">
                    UF
                  </label>
                  <input
                    type="text"
                    id="state"
                    name="state"
                    className="form-control"
                    value={edit.state}
                    onChange={this.handleEditChange}
                    placeholder="UF"
                    maxLength={2}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="mobile" className="form-label">
                    Celular
                  </label>
                  <InputMask
                    mask="(99) 9 9999-9999"
                    id="mobile"
                    name="mobile"
                    className="form-control"
                    value={edit.mobile}
                    onChange={this.handleEditChange}
                    placeholder="Digite o celular"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="phone" className="form-label">
                    Fone Comercial
                  </label>
                  <InputMask
                    mask="(99) 9999-9999"
                    id="phone"
                    name="phone"
                    className="form-control"
                    value={edit.phone}
                    onChange={this.handleEditChange}
                    placeholder="Digite o telefone"
                  />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  value={edit.email}
                  onChange={this.handleEditChange}
                  placeholder="Digite o email"
                />
              </div>
            </form>
          </div>
          <div className="modal-fotter">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={this.handleEditSubmit}
            >
              <FontAwesomeIcon icon={faSave} /> Atualizar
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.trash).length > 0}
          title="Tem certeza de que deseja continuar?"
          onAfterClose={() => {
            this.setState({ trash: false });
          }}
        >
          <div className="content">
            <span>
              Se você confirmar a exclusão, não será possível recuperar os dados
              posteriormente.
            </span>
          </div>
          <div className="modal-fotter">
            <button
              className="btn btn-danger"
              onClick={(e) => {
                const { trash } = this.state;
                e.target.classList.add("load");

                fetch(`${apiUrl}/store/delete`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.getItem("token"),
                  },
                  body: JSON.stringify({
                    token: trash.id,
                  }),
                })
                  .then((response) => {
                    e.target.classList.remove("load");
                    if (response.status == 200) {
                      this.setState((prevState) => ({
                        data: prevState.data.filter(
                          (item) => item.id !== trash.id
                        ),
                        trash: {},
                      }));
                      toast.success("Loja excluida com sucesso.");
                    }
                  })
                  .catch(() => {
                    e.target.classList.remove("load");
                    toast.error("Erro ao salvar funcionário.");
                  });
              }}
            >
              <FontAwesomeIcon icon={faTrash} /> Excluir
            </button>
          </div>
        </StaticDialog>
      </div>
    );
  }
}

export default Stores;
