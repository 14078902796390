import {
  faPen,
  faSave,
  faSearch,
  faTrash,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { StaticDialog } from "react-st-modal";
import { apiUrl } from "../../comps/apiUrl";
import { toast } from "react-toastify";

class CadastroMaquina extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      add: false,
      dados: [],
      currentPage: 1,
      totalItems: 0,
      itemsPerPage: 15,
      loader: true,
      edit: {},
      trash: {},
    };
  }

  componentDidMount() {
    document.title = "Cadastro de Equipamentos";
    this.fetchData(this.state.currentPage);
  }

  formatarMoeda = (valor) => {
    // Remove caracteres que não sejam números
    const apenasNumeros = valor.replace(/\D/g, "");
    // Converte para número e formata em BRL
    const valorFormatado = (Number(apenasNumeros) / 100).toLocaleString(
      "pt-BR",
      {
        style: "currency",
        currency: "BRL",
      }
    );
    return valorFormatado;
  };

  fetchData(page) {
    fetch(`${apiUrl}/maquinas?page=${page}&limit=${this.state.itemsPerPage}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status == 510) {
        localStorage.clear();
        window.location.href = "/";
        return;
      }

      response.json().then((data) => {
        const totalItems = data.totalItems; // Simulado, substitua pelo valor real do backend.
        const totalPages = Math.ceil(totalItems / this.state.itemsPerPage);

        this.setState({
          items: data.items,
          currentPage: page,
          totalPages,
          loader: false,
        });
      });
    });
  }
  handlePageClick = (page) => {
    this.fetchData(page);
  };
  handleNextPage = () => {
    this.fetchData(this.state.currentPage + 1);
  };

  handlePreviousPage = () => {
    if (this.state.currentPage > 1) {
      this.fetchData(this.state.currentPage - 1);
    }
  };
  renderPagination() {
    const { currentPage, totalPages } = this.state;

    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => this.handlePageClick(i)}
          style={{
            backgroundColor: i === currentPage ? "#007bff" : "#fff",
            color: i === currentPage ? "#fff" : "#000",
            border: "1px solid #ddd",
            cursor: "pointer",
          }}
        >
          {i}
        </button>
      );
    }

    return (
      <div>
        <button
          className="btn btn-ligth"
          onClick={() => this.handlePageClick(currentPage - 1)}
          disabled={currentPage === 1}
          style={{
            cursor: currentPage === 1 ? "not-allowed" : "pointer",
          }}
        >
          &lt;
        </button>

        {pages}

        <button
          onClick={() => this.handlePageClick(currentPage + 1)}
          className="btn btn-ligth"
          disabled={currentPage === totalPages}
          style={{
            cursor: currentPage === totalPages ? "not-allowed" : "pointer",
          }}
        >
          &gt;
        </button>
      </div>
    );
  }

  handleChange = (event) => {
    const { id, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      dados: {
        ...prevState.dados, // Mantém os outros campos do objeto `dados`
        [id]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };
  handleEdit = (event) => {
    const { id, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      edit: {
        ...prevState.edit, // Mantém os outros campos do objeto `dados`
        [id]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };

  render() {
    if (this.state.loader) {
      return (
        <div class="area-loader">
          <div class="loader"></div>
        </div>
      );
    }

    return (
      <div className="content">
        <div className="card">
          <div className="card-header d-flex align-items-center justify-content-between">
            <strong>Cadastro de Equipamentos</strong>
            <button
              class="btn btn-primary"
              onClick={() => {
                this.setState({ add: true });
              }}
            >
              {" "}
              <FontAwesomeIcon icon={faUserPlus} /> Cadastrar equipamento
            </button>
          </div>
          <div className="card-body">
            {this.state.items.length > 0 ? (
              <>
                <div class="mb-3 d-flex justify-content-center">
                  <form className="w-50">
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control "
                        id="searchInput"
                        onChange={(e) => {
                          this.setState({ search: e.target.value });
                        }}
                        placeholder="Digite:"
                      />
                      <button
                        type="submit"
                        class="btn btn-primary"
                        onClick={(e) => {
                          e.target.classList.add("load");
                          var search = this.state.search;
                          fetch(`${apiUrl}/buscar-equipamento?s=${search}`, {
                            method: "GET",
                            headers: {
                              "Content-Type": "application/json",
                            },
                          })
                            .then((response) => response.json())
                            .then((data) => {
                              e.target.classList.remove("load");
                              this.setState({ items: data });
                            });
                        }}
                      >
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </div>
                  </form>
                </div>
                <table class="table table-striped table-bordered arvore-l">
                  <thead class="thead-dark">
                    <tr>
                      <th>ID</th>

                      <th style={{ width: 300 }}>Nome do produto</th>

                      <th>Estoque Matriz</th>
                      <th>Estoque Filial</th>
                      <th>Estoque Total</th>
                      <th>Diária</th>
                      <th>Semanal</th>
                      <th>Quizenal</th>
                      <th>Mensal</th>
                      <th>Valor de venda</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.items.map((item, index) => (
                      <>
                        <tr>
                          <td>{item.id_produto}</td>

                          <td style={{ width: 300 }}>{item.descricao}</td>
                          <td>{item.stockone}</td>
                          <td>{item.stocktwo}</td>
                          <td>
                            {new Number(item.stockone) +
                              new Number(item.stocktwo)}
                          </td>
                          <td>{item.day}</td>
                          <td>{item.week}</td>
                          <td>{item.biweek}</td>
                          <td>{item.month}</td>
                          <td>{item.custograde}</td>

                          <td className="btns">
                            <button
                              className="btn btn-primary"
                              onClick={(e) => {
                                console.log(item);
                                this.setState({ edit: item });
                              }}
                            >
                              <FontAwesomeIcon icon={faPen} />
                            </button>
                            <button className="btn btn-danger">
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <>Não há resultados</>
            )}
          </div>
          <div
            className="card-footer"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {this.renderPagination()}
          </div>
        </div>

        <StaticDialog
          isOpen={this.state.add}
          title="Cadastrar Equipamento"
          onAfterClose={(result) => {
            this.setState({ add: false });
          }}
        >
          <div className="content">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                console.log(this.state.dados);
              }}
            >
              <div class="row md-6 d-flex">
                <div class="col mb-4">
                  <label for="machineName" class="form-label">
                    Nome do Equipamento
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    onChange={this.handleChange}
                    value={this.state.dados.descricao || ""}
                    id="descricao"
                    required=""
                  />
                </div>
              </div>

              <div class="row md-6 d-flex">
                <div class="col mb-3">
                  <label for="stockone" class="form-label">
                    Estoque Matriz
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    onChange={(e) => {
                      var value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        this.setState({
                          dados: { ...this.state.dados, stockone: value },
                        });
                      }
                    }}
                    value={this.state.dados.stockone || ""}
                    id="stockone"
                    required=""
                  />
                </div>
                <div class="col mb-3">
                  <label for="price" class="form-label">
                    Estoque Filial
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="stocktwo"
                    onChange={(e) => {
                      var value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        this.setState({
                          dados: { ...this.state.dados, stocktwo: value },
                        });
                      }
                    }}
                    value={this.state.dados.stocktwo || ""}
                    required=""
                  />
                </div>
              </div>

              <div className="mt-1 mb-1">
                <input
                  type="checkbox"
                  value={this.state.dados.loca}
                  checked={this.state.dados.sale}
                  onChange={(e) => {
                    this.setState({
                      dados: { ...this.state.dados, sale: e.target.checked },
                    });
                  }}
                  id="sale"
                />{" "}
                <label for="sale"> Venda</label>
                {this.state.dados.sale == true && (
                  <div class="col mt-3 mb-3">
                    <label for="custograde" class="form-label">
                      Valor de Venda
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="custograde"
                      onChange={(e) => {
                        this.setState({
                          dados: {
                            ...this.state.dados,
                            custograde: this.formatarMoeda(e.target.value),
                          },
                        });
                      }}
                      value={this.state.dados.custograde || ""}
                      required=""
                    />
                  </div>
                )}
              </div>

              <div className="">
                <input
                  type="checkbox"
                  value={this.state.dados.loca}
                  checked={this.state.dados.loca}
                  onChange={(e) => {
                    this.setState({
                      dados: { ...this.state.dados, loca: e.target.checked },
                    });
                  }}
                  id="lcs"
                />{" "}
                <label for="lcs"> Locação</label>
              </div>

              {this.state.dados.loca == true && (
                <>
                  <div className="mt-3 mb-3">
                    <div class="row md-6 d-flex">
                      <div class="col mb-3">
                        <label for="day" class="form-label">
                          Diária
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          onChange={(e) => {
                            this.setState({
                              dados: {
                                ...this.state.dados,
                                day: this.formatarMoeda(e.target.value),
                              },
                            });
                          }}
                          value={this.state.dados.day || ""}
                          id="day"
                          required=""
                        />
                      </div>
                      <div class="col mb-3">
                        <label for="week" class="form-label">
                          Semanal
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={this.state.dados.week || ""}
                          onChange={(e) => {
                            this.setState({
                              dados: {
                                ...this.state.dados,
                                week: this.formatarMoeda(e.target.value),
                              },
                            });
                          }}
                          id="week"
                          required=""
                        />
                      </div>
                    </div>

                    <div class="row md-6 d-flex">
                      <div class="col mb-3">
                        <label for="biweek" class="form-label">
                          Quizenal
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={this.state.dados.biweek || ""}
                          onChange={(e) => {
                            this.setState({
                              dados: {
                                ...this.state.dados,
                                biweek: this.formatarMoeda(e.target.value),
                              },
                            });
                          }}
                          id="biweek"
                          required=""
                        />
                      </div>
                      <div class="col mb-3">
                        <label for="month" class="form-label">
                          Mensal
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          onChange={(e) => {
                            this.setState({
                              dados: {
                                ...this.state.dados,
                                month: this.formatarMoeda(e.target.value),
                              },
                            });
                          }}
                          value={this.state.dados.month || ""}
                          id="month"
                          required=""
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </form>
          </div>

          <div className="modal-fotter">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                var dados = this.state.dados;

                console.log(dados);

                fetch(`${apiUrl}/cadastrar-maquina`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    // outras headers, se necessário
                  },
                  body: JSON.stringify(dados), // corpo da requisição em formato JSON
                })
                  .then((response) => {
                    console.log(response);
                    if (response.status == 200) {
                      response.json().then((data) => {
                        this.setState((prevState) => ({
                          items: [
                            {
                              id_produto: data.id,
                              descricao: dados.descricao,
                              stockone: dados.stockone,
                              stocktwo: dados.stocktwo,
                              day: dados.day,
                              week: dados.week,
                              biweek: dados.biweek,
                              month: dados.month,
                              sale: dados.sale,
                              custograde: dados.custograde,
                              loca: dados.loca,
                            },
                            ...prevState.items,
                          ],
                          add: false,
                        }));
                        toast.success("Equipamento cadastrado com sucesso!");
                      });
                    }
                  })
                  .catch((err) => {
                    toast.error("Falha na comunicação com o servidor!");
                  });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.edit).length > 0}
          title="Editar Equipamento"
          onAfterClose={(result) => {
            this.setState({ edit: {} });
          }}
        >
          <div className="content">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                console.log(this.state.edit);
              }}
            >
              <div class="row md-6 d-flex">
                <div class="col mb-4">
                  <label for="machineName" class="form-label">
                    Nome do Equipamento
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    onChange={this.handleEdit}
                    value={this.state.edit.descricao || ""}
                    id="descricao"
                    required=""
                  />
                </div>
              </div>

              <div class="row md-6 d-flex">
                <div class="col mb-3">
                  <label for="stockone" class="form-label">
                    Estoque Matriz
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    onChange={(e) => {
                      var value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        this.setState({
                          edit: { ...this.state.edit, stockone: value },
                        });
                      }
                    }}
                    value={this.state.edit.stockone || ""}
                    id="stockone"
                    required=""
                  />
                </div>
                <div class="col mb-3">
                  <label for="price" class="form-label">
                    Estoque Filial
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="stocktwo"
                    onChange={(e) => {
                      var value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        this.setState({
                          edit: { ...this.state.edit, stocktwo: value },
                        });
                      }
                    }}
                    value={this.state.edit.stocktwo || ""}
                    required=""
                  />
                </div>
              </div>

              <div className="mt-1 mb-1">
                <input
                  type="checkbox"
                  value={this.state.edit.loca}
                  checked={this.state.edit.sale}
                  onChange={(e) => {
                    this.setState({
                      edit: { ...this.state.edit, sale: e.target.checked },
                    });
                  }}
                  id="sale"
                />{" "}
                <label for="sale"> Venda</label>
                {this.state.edit.sale == true && (
                  <div class="col mt-3 mb-3">
                    <label for="custograde" class="form-label">
                      Valor de Venda
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="custograde"
                      onChange={(e) => {
                        this.setState({
                          edit: {
                            ...this.state.edit,
                            custograde: this.formatarMoeda(e.target.value),
                          },
                        });
                      }}
                      value={this.state.edit.custograde || ""}
                      required=""
                    />
                  </div>
                )}
              </div>

              <div className="">
                <input
                  type="checkbox"
                  value={this.state.edit.loca}
                  checked={this.state.edit.loca}
                  onChange={(e) => {
                    this.setState({
                      edit: { ...this.state.edit, loca: e.target.checked },
                    });
                  }}
                  id="lcs"
                />{" "}
                <label for="lcs"> Locação</label>
              </div>

              {this.state.edit.loca == true && (
                <>
                  <div className="mt-3 mb-3">
                    <div class="row md-6 d-flex">
                      <div class="col mb-3">
                        <label for="day" class="form-label">
                          Diária
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          onChange={(e) => {
                            this.setState({
                              edit: {
                                ...this.state.edit,
                                day: this.formatarMoeda(e.target.value),
                              },
                            });
                          }}
                          value={this.state.edit.day || ""}
                          id="day"
                          required=""
                        />
                      </div>
                      <div class="col mb-3">
                        <label for="week" class="form-label">
                          Semanal
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={this.state.edit.week || ""}
                          onChange={(e) => {
                            this.setState({
                              edit: {
                                ...this.state.edit,
                                week: this.formatarMoeda(e.target.value),
                              },
                            });
                          }}
                          id="week"
                          required=""
                        />
                      </div>
                    </div>

                    <div class="row md-6 d-flex">
                      <div class="col mb-3">
                        <label for="biweek" class="form-label">
                          Quizenal
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={this.state.edit.biweek || ""}
                          onChange={(e) => {
                            this.setState({
                              edit: {
                                ...this.state.edit,
                                biweek: this.formatarMoeda(e.target.value),
                              },
                            });
                          }}
                          id="biweek"
                          required=""
                        />
                      </div>
                      <div class="col mb-3">
                        <label for="month" class="form-label">
                          Mensal
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          onChange={(e) => {
                            this.setState({
                              edit: {
                                ...this.state.edit,
                                month: this.formatarMoeda(e.target.value),
                              },
                            });
                          }}
                          value={this.state.edit.month || ""}
                          id="month"
                          required=""
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </form>
          </div>

          <div className="modal-fotter">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                var dados = this.state.edit;

                fetch(`${apiUrl}/atualizar-maquina`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    // outras headers, se necessário
                  },
                  body: JSON.stringify(dados), // corpo da requisição em formato JSON
                })
                  .then((response) => {
                    console.log(response);
                    if (response.status == 200) {
                      response.json().then((data) => {
                        var {
                          id_produto,
                          descricao,
                          stockone,
                          stocktwo,
                          day,
                          week,
                          biweek,
                          month,
                          sale,
                          custograde,
                          loca,
                        } = this.state.edit;

                        var newItems = {
                          descricao,
                          stockone,
                          stocktwo,
                          day,
                          week,
                          biweek,
                          month,
                          sale,
                          custograde,
                          loca,
                        };

                        this.setState((prevState) => ({
                          items: prevState.items.map((item) =>
                            item.id_produto === id_produto
                              ? { ...item, ...newItems }
                              : item
                          ),
                        }));

                        this.setState({ edit: {} });

                        toast.success("Equipamento atualizado com sucesso!");
                      });
                    }
                  })
                  .catch((err) => {
                    toast.error("Falha na comunicação com o servidor!");
                  });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.trash).length > 0}
          title="Tem certeza de que deseja continuar?"
          onAfterClose={(result) => {
            this.setState({ trash: {} });
          }}
        >
          <div style={{ margin: 10 }}>
            Se você confirmar a exclusão, não será possível recuperar os dados
            posteriormente.
          </div>

          <div className="modal-fotter">
            <button
              className="btn btn-danger"
              onClick={(e) => {
                e.target.classList.add("load");
                var id = this.state.trash.id;
                fetch(`${apiUrl}/deletar-maquina`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    // outras headers, se necessário
                  },
                  body: JSON.stringify({ id }), // corpo da requisição em formato JSON
                })
                  .then((response) => {
                    e.target.classList.remove("load");
                    if (response.ok) {
                      this.setState((prevState) => ({
                        items: prevState.items.filter((item) => item.id !== id),
                        trash: {},
                      }));

                      toast.success("Equipamento excluido com sucesso");
                    }
                  })
                  .catch((error) => {
                    toast.error("Erro ao enviar dados:", error);
                  });
              }}
            >
              <FontAwesomeIcon icon={faTrash} /> Excluir
            </button>
          </div>
        </StaticDialog>
      </div>
    );
  }
}

export default CadastroMaquina;
