import {
  faBuilding,
  faClipboard,
  faClock,
  faEnvelope,
  faEye,
  faFileInvoice,
  faHome,
  faIdCard,
  faMapMarkerAlt,
  faMinus,
  faMobileAlt,
  faPen,
  faPhone,
  faPlus,
  faSave,
  faTrash,
  faUser,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { apiUrl } from "../../comps/apiUrl";
import { toast } from "react-toastify";
import { StaticDialog } from "react-st-modal";
import { TextField, Button } from "@mui/material";
import InputMask from "react-input-mask";
class Lease extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      showModal: false,
      data: [],
      dados: { type: "location" },
      dataClient: [],
      addEquipament: false,
      busca: "",
      rBusca: [],

      BuscaM: "",
      rBuscaM: [],

      products: [],
      newProduct: false,
      equipaments: [],
      cpfCnpj: "",
    };
  }
  handleCpfCnpjChange = (e) => {
    console.log(e.target);
    function cnpj(v) {
      v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
      v = v.replace(/^(\d{2})(\d)/, "$1.$2"); //Coloca ponto entre o segundo e o terceiro dígitos
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3"); //Coloca ponto entre o quinto e o sexto dígitos
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2"); //Coloca uma barra entre o oitavo e o nono dígitos
      v = v.replace(/(\d{4})(\d)/, "$1-$2"); //Coloca um hífen depois do bloco de quatro dígitos
      return v;
    }

    function cpf(v) {
      v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
      v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
      v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
      //de novo (para o segundo bloco de números)
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); //Coloca um hífen entre o terceiro e o quarto dígitos
      return v;
    }

    if (e.target.value.length > 18) return;

    if (e.target.value.length <= 14) {
      this.setState({
        cpfCnpj: cpf(e.target.value),
      });
    } else {
      this.setState({
        cpfCnpj: cnpj(e.target.value),
      });
    }
  };
  DateNow() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Mês começa em 0
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");

    // Formata no padrão YYYY-MM-DDTHH:mm
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  componentDidMount() {
    document.title = "Locação";
    this.setState({
      dados: {
        start_day: this.DateNow(),
      },
    });
    fetch(`${apiUrl}/leaser`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        if (response.status == 510) {
          window.localStorage.clear();
          window.location.href = "/";
        }
        if (response.status == 200) {
          response.json().then((data) => {
            this.setState({
              data,
              loader: false,
            });
          });
        }
      })
      .catch((error) => {
        toast.error("Ocorreu um erro ao carregar as informações");
      });
  }

  TypeBlock(type) {
    if (type == 0) {
      return "Desbloqueado";
    } else if (type == 1) {
      return "Bloqueado Para Locação";
    } else if (type == 2) {
      return "Bloqueado Para Serviço";
    } else if (type == 3) {
      return "Bloqueio Total";
    } else {
      return "Desbloqueado";
    }
  }

  formatDate(dateString) {
    var e = dateString.split("-");
    var dateString = e[2] + "/" + e[1] + "/" + e[0];
    return dateString;
  }
  handleStartDateChange = (event) => {
    this.setState({ startDate: event.target.value }, this.calculateDifference);
  };

  handleEndDateChange = (event) => {
    this.setState({ endDate: event.target.value }, this.calculateDifference);
  };

  calculateDifference = () => {
    const { startDate, endDate } = this.state;

    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);

      if (
        start instanceof Date &&
        end instanceof Date &&
        !isNaN(start) &&
        !isNaN(end)
      ) {
        const differenceInTime = end.getTime() - start.getTime();
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);

        this.setState({
          dayDifference: Math.max(0, Math.floor(differenceInDays)),
        });
      } else {
        this.setState({ dayDifference: null });
      }
    } else {
      this.setState({ dayDifference: null });
    }
  };
  formatarMoeda = (valor) => {
    // Remove caracteres que não sejam números
    const apenasNumeros = valor.replace(/\D/g, "");
    // Converte para número e formata em BRL
    const valorFormatado = (Number(apenasNumeros) / 100).toLocaleString(
      "pt-BR",
      {
        style: "currency",
        currency: "BRL",
      }
    );
    return valorFormatado;
  };

  handleChange = (event) => {
    const { id, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      dados: {
        ...prevState.dados, // Mantém os outros campos do objeto `dados`
        [id]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };

  handlenCChange = (event) => {
    const { name, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      dataClient: {
        ...prevState.dataClient, // Mantém os outros campos do objeto `dados`
        [name]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };

  EndDay() {
    var billing = {
      1: 1,
      2: 7,
      3: 15,
      4: 30,
      5: 365,
    };

    const date = new Date(this.state.dados.start_day);
    if (!isNaN(date)) {
      var dias =
        this.state.dados.period !== undefined
          ? this.state.dados.period * billing[this.state.dados.faturamento]
          : 0;
      date.setDate(date.getDate() + dias);

      // Construção manual do formato 'YYYY-MM-DD HH:mm'
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}`;
    } else {
      return 0;
    }
  }
  calculateTotal = () => {
    var billing = {
      1: 1,
      2: 7,
      3: 15,
      4: 30,
      5: 365,
    };
    return (
      this.state.products.reduce((total, item) => {
        const selectedPrice = item.price[this.state.dados.faturamento]; // Preço selecionado com base no faturamento
        return total + selectedPrice * item.quantity; // Soma o preço de cada item com a quantidade
      }, 0) * this.state.dados.period
    ); // Multiplica o total final pelos dias
  };

  render() {
    if (this.state.loader) {
      return (
        <div className="area-loader">
          <div className="loader"></div>
        </div>
      );
    }
    var { dayDifference } = this.state;

    const total = this.calculateTotal(); // Total calculado
    var EndDay = this.EndDay();
    return (
      <div className="content">
        <div className="card">
          <div className="card-header d-flex align-items-center justify-content-between">
            <strong>Locação de Equipamentos</strong>
            <Link className="btn btn-success" to="/lease-history">
              <FontAwesomeIcon icon={faClock} /> Histórico de Locações
            </Link>
          </div>
          <div className="card-body">
            {this.state.dados.cliente == undefined ? (
              <div className="d-flex">
                <button
                  type="button"
                  class="btn btn-primary mb-2"
                  onClick={() => this.setState({ showModal: true })}
                >
                  <FontAwesomeIcon icon={faUserPlus} /> Buscar cliente
                </button>
                <button
                  type="button"
                  class="btn btn-primary mb-2"
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.setState({ newC: true })}
                >
                  <FontAwesomeIcon icon={faUserPlus} /> Novo cliente
                </button>
              </div>
            ) : (
              <>
                <label>Cliente</label>
                <table class="table table-striped table-bordered arvore-l mt-2">
                  <thead class="thead-dark">
                    <tr>
                      <th>Nome</th>
                      <th>Documento</th>
                      <th>Opção</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{this.state.dados.cliente.nome}</td>
                      <td>{this.state.dados.cliente.cnpj_cpf}</td>
                      <td>
                        <button
                          class="btn btn-danger"
                          onClick={(e) => {
                            this.setState((prevState) => {
                              const { cliente, ...rest } = prevState.dados; // Desestrutura e exclui "cliente"
                              return {
                                dados: rest, // Atualiza "dados" com o restante das propriedades
                              };
                            });
                          }}
                        >
                          Excluir
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}

            <div className="card mt-2 mb-2">
              <div className="card-header d-flex justify-content-between">
                <h3>Equipamentos</h3>
              </div>
              <div className="card-body">
                {this.state.products.length > 0 ? (
                  <>
                    <table class="table table-striped table-bordered arvore-l">
                      <thead class="thead-dark">
                        <tr>
                          <th>Código de barra</th>
                          <th scope="col">Nome</th>
                          <th>Estoque</th>
                          {this.state.dados.faturamento != undefined && (
                            <>
                              <th>Valor unitário</th>
                            </>
                          )}
                          <th>Quantidade</th>
                          {this.state.dados.faturamento != undefined && (
                            <>
                              <th>Total</th>
                            </>
                          )}
                          <th scope="col">Opção</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.products.map((product) => (
                          <tr>
                            <td>{product.codigo_barra}</td>
                            <td>{product.name}</td>
                            <td>
                              {this.state.data.store.id == 1
                                ? product.stockone
                                : product.stocktwo}
                            </td>
                            {this.state.dados.faturamento != undefined && (
                              <>
                                <td>
                                  {product.price[
                                    this.state.dados.faturamento
                                  ].toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  })}
                                </td>
                              </>
                            )}
                            <td style={{ width: 200 }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    var id = product.id;
                                    this.setState((prevState) => {
                                      const novosItens = prevState.products.map(
                                        (item) => {
                                          if (item.id === id) {
                                            // Verifica se a quantidade é maior que 1 antes de diminuir
                                            const novaQuantidade =
                                              item.quantity > 1
                                                ? item.quantity - 1
                                                : 1;
                                            return {
                                              ...item,
                                              quantity: novaQuantidade, // Atualiza a quantidade
                                            };
                                          }
                                          return item;
                                        }
                                      );

                                      return { products: novosItens };
                                    });
                                  }}
                                >
                                  <FontAwesomeIcon icon={faMinus} />
                                </button>
                                <input
                                  type="text"
                                  style={{
                                    marginLeft: 10,
                                    marginRight: 10,
                                    width: 50,
                                    textAlign: "center",
                                  }}
                                  value={product.quantity}
                                  className="form-control"
                                  disabled
                                />
                                <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    var id = product.id;
                                    var max =
                                      this.state.data.store.id == 1
                                        ? product.stockone
                                        : product.stocktwo;

                                    this.setState((prevState) => {
                                      const novosItens = prevState.products.map(
                                        (item) => {
                                          if (item.id === id) {
                                            if (item.quantity >= max) {
                                              toast.warn(
                                                "Quantidade máxima atingida"
                                              );
                                              return item; // Retorna o item sem alteração
                                            }

                                            return {
                                              ...item,
                                              quantity: item.quantity + 1, // Incrementa a quantidade
                                            };
                                          }
                                          return item;
                                        }
                                      );

                                      return { products: novosItens };
                                    });
                                  }}
                                >
                                  <FontAwesomeIcon icon={faPlus} />
                                </button>
                              </div>
                            </td>
                            {this.state.dados.faturamento != undefined && (
                              <>
                                <td>
                                  {(
                                    product.quantity *
                                    product.price[this.state.dados.faturamento]
                                  ).toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  })}
                                </td>
                              </>
                            )}
                            <td>
                              <button
                                class="btn btn-danger"
                                onClick={(e) => {
                                  var id = product.id;
                                  this.setState((prevState) => {
                                    // Filtra o array de itens, removendo o item pelo id
                                    const novosItens =
                                      prevState.products.filter(
                                        (item) => item.id !== id
                                      );

                                    return { products: novosItens }; // Atualiza o estado com o novo array de itens
                                  });
                                }}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn btn-primary"
                        onClick={(e) => {
                          this.setState({
                            addEquipament: true,
                          });
                        }}
                      >
                        <FontAwesomeIcon icon={faPlus} /> Adicionar equipamento
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-primary"
                      onClick={(e) => {
                        this.setState({
                          addEquipament: true,
                        });
                      }}
                      disabled={
                        this.state.dados.cliente == undefined ? true : false
                      }
                    >
                      <FontAwesomeIcon icon={faPlus} /> Adicionar equipamento
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-4">
                <label for="nome-cliente" class="form-label">
                  Tipo
                </label>
                <select
                  class="form-control"
                  id="vendedor"
                  onChange={(e) => {
                    this.setState({
                      data: {
                        ...this.state.data, // Mantém os outros campos do objeto `data`
                        ["type"]: e.target.value, // Atualiza apenas o campo correspondente
                      },
                    });
                  }}
                  value={this.state.data.type}
                >
                  <option value="location">Locação</option>
                  <option value="sale">Venda</option>
                </select>
              </div>
              <div class="col-md-4">
                <label for="nome-cliente" class="form-label">
                  Vendedor
                </label>

                <input
                  type="text"
                  class="form-control"
                  value={this.state.data.sellers.name}
                  disabled
                />
              </div>
              <div class="col-md-4">
                <label for="loja" class="form-label">
                  Loja
                </label>
                <input
                  type="text"
                  class="form-control"
                  value={this.state.data.store.name}
                  disabled
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-md-3">
                <label for="data-inicial" class="form-label">
                  Faturamento
                </label>
                <select
                  className="form-control"
                  id="faturamento"
                  name="faturamento"
                  onChange={(e) => {
                    if (e.target.value == "0") {
                      e.preventDefault();
                      return false;
                    }

                    this.handleChange(e);
                  }}
                  value={this.state.dados.faturamento}
                  disabled={this.state.products.length == 0 ? true : false}
                >
                  <option value="0">Selecione o período do faturamento</option>
                  <option value="1">Diária</option>
                  <option value="2">Semanal</option>
                  <option value="3">Quinzenal</option>
                  <option value="4">Mensal</option>
                  <option value="5">Anual</option>
                </select>
              </div>
              <div class="col-md-3">
                <label for="data-inicial" class="form-label">
                  Quantidade de período
                </label>
                <input
                  type="text"
                  class="form-control"
                  value={this.state.dados.period}
                  onChange={(e) => {
                    var value = e.target.value.replace(/\D/g, "");

                    this.setState((prevState) => ({
                      dados: {
                        ...prevState.dados, // Mantém os outros campos do objeto `dados`
                        ["period"]: value, // Atualiza apenas o campo correspondente
                      },
                    }));
                  }}
                  disabled={
                    this.state.dados.faturamento == undefined ? true : false
                  }
                />
              </div>
              <div class="col-md-3">
                <label for="data-inicial" class="form-label">
                  Data Inicial
                </label>
                <input
                  type="datetime-local"
                  id="data-inicial"
                  class="form-control"
                  required=""
                  value={this.state.dados.start_day}
                  disabled={true}
                />
              </div>
              <div class="col-md-3">
                <label for="data-final" class="form-label">
                  Data Final
                </label>
                <input
                  type="datetime-local"
                  id="data-final"
                  class="form-control"
                  value={EndDay}
                  required=""
                  disabled={true}
                />
              </div>
            </div>

            <div class="row mb-3 d-flex align-items-center">
              <div class="col-md-2">
                <label for="dias-locacao" class="form-label">
                  Valor da locação
                </label>
                <input
                  value={
                    isNaN(total)
                      ? "R$ 0,00"
                      : total.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })
                  }
                  id="dias-locacao"
                  class="form-control"
                  disabled
                />
              </div>
              <div class="col-md-2">
                <label for="dias-locacao" class="form-label">
                  Multa por Atraso
                </label>
                <input
                  value={this.state.dados.multa}
                  onChange={(e) => {
                    this.setState((prevState) => ({
                      dados: {
                        ...prevState.dados, // Mantém os outros campos do objeto `dados`
                        ["multa"]: this.formatarMoeda(e.target.value), // Atualiza apenas o campo correspondente
                      },
                    }));
                  }}
                  class="form-control"
                />
              </div>
              <div class="col-md-2">
                <label for="dias-locacao" class="form-label">
                  Frete
                </label>
                <input
                  value={this.state.dados.frete}
                  onChange={(e) => {
                    this.setState((prevState) => ({
                      dados: {
                        ...prevState.dados, // Mantém os outros campos do objeto `dados`
                        ["frete"]: this.formatarMoeda(e.target.value), // Atualiza apenas o campo correspondente
                      },
                    }));
                  }}
                  class="form-control"
                />
              </div>
              <div class="col-md-3">
                <label for="dias-locacao" class="form-label">
                  Observações
                </label>
                <textarea
                  class="form-control"
                  value={this.state.dados.obs}
                  onChange={(e) => {
                    this.setState((prevState) => ({
                      dados: {
                        ...prevState.dados, // Mantém os outros campos do objeto `dados`
                        ["obs"]: e.target.value, // Atualiza apenas o campo correspondente
                      },
                    }));
                  }}
                  placeholder="Digite:"
                />
              </div>
              <div class="col-md-3">
                <label for="dias-locacao" class="form-label">
                  Forma de pagamento
                </label>
                <select
                  id="tpay"
                  class="form-control"
                  onChange={(e) => this.handleChange(e)}
                  required=""
                >
                  <option value="" disabled="" selected="">
                    Selecione
                  </option>
                  <option value="cartaoc">Cartão de Crédito</option>
                  <option value="cartaod">Cartão de Débito</option>
                  <option value="dinheiro">Dinheiro</option>
                  <option value="pix">PIX</option>
                  <option value="cheque">Cheque</option>
                </select>
              </div>
            </div>

            {dayDifference != null && (
              <>
                <div class="row mb-3">
                  <div class="col-md-3">
                    <label for="dias-locacao" class="form-label">
                      Dias de Locação
                    </label>
                    <input
                      value={dayDifference != null ? dayDifference : ""}
                      id="dias-locacao"
                      class="form-control"
                      disabled="true"
                    />
                  </div>

                  <div class="col-md-3">
                    <label for="valor-total" class="form-label">
                      Valor Total
                    </label>
                    <input
                      type="text"
                      id="valor-total"
                      value={(
                        dayDifference *
                        this.state.dados.quantidade *
                        this.state.dados.price
                      ).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                      class="form-control"
                      disabled="true"
                    />
                  </div>

                  <div class="col-md-3">
                    <label for="forma-pagamento" class="form-label">
                      Forma de Pagamento
                    </label>
                    <select
                      id="tpay"
                      class="form-control"
                      onChange={(e) => this.handleChange(e)}
                      required=""
                    >
                      <option value="" disabled="" selected="">
                        Selecione
                      </option>
                      <option value="cartao">Cartão de Crédito</option>
                      <option value="cartao">Cartão de Débito</option>
                      <option value="dinheiro">Dinheiro</option>
                      <option value="pix">PIX</option>
                      <option value="cheque">Cheque</option>
                    </select>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="card-footer">
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  var dados = this.state.dados;

                  if (this.state.dados.tpay == undefined) {
                    toast.warn("Selecione uma forma de pagamento");
                    return;
                  }

                  var send = {
                    client: this.state.dados.cliente,
                    products: this.state.products,
                    seller: this.state.data.sellers.name,
                    location: {
                      billing: dados.faturamento,
                      period: dados.period,
                      start_day: dados.start_day,
                      end_day: EndDay,
                      location_value: total,
                      fine: this.state.dados.multa,
                      frete: this.state.dados.frete,
                      obs: this.state.dados.obs,
                      tpay: this.state.dados.tpay,
                    },
                  };

                  fetch(`${apiUrl}/new/location`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      authorization: localStorage.getItem("token"),
                    },
                    body: JSON.stringify(send),
                  })
                    .then((res) => {
                      if (res.status == 200) {
                        res.json().then((data) => {
                          toast.success("Locação adicionada com sucesso!");

                          this.props.navigate(`/`);
                        });
                      } else {
                        toast.error("Ocorreu um erro ao adicionar a locação");
                      }
                    })
                    .catch((error) => {
                      toast.error("Ocorreu um erro ao adicionar a locação");
                      console.error(error);
                    });
                }}
              >
                <FontAwesomeIcon icon={faSave} /> Salvar
              </button>
            </div>
          </div>
        </div>

        <StaticDialog
          isOpen={this.state.addEquipament}
          title="Buscar equipamento"
          onAfterClose={(result) => {
            this.setState({ addEquipament: false, BuscaM: "", rBuscaM: [] });
          }}
        >
          <div className="content">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (this.state.BuscaM.trim() == "") {
                  toast.warn("Digite algo para buscar");
                  e.target.classList.remove("load");
                  return;
                }

                fetch(`${apiUrl}/get-equipaments`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.getItem("token"),
                  },
                  body: JSON.stringify({ name: this.state.BuscaM }),
                })
                  .then((res) => {
                    e.target.classList.remove("load");
                    if (res.status === 404) {
                      this.setState({ rBuscaM: null });

                      return null;
                    }
                    if (!res.ok) {
                      throw new Error("Erro ao buscar os dados");
                    }
                    return res.json();
                  })
                  .then((data) => {
                    if (data) {
                      this.setState({ rBuscaM: data });
                    }
                  })
                  .catch((error) => {
                    e.target.classList.remove("load");
                    toast.error("Ocorreu um erro ao buscar o cliente");
                    console.error(error);
                  });
              }}
            >
              <div className="input-group">
                <input
                  type="text"
                  placeholder="Digite..."
                  onChange={(e) => this.setState({ BuscaM: e.target.value })}
                  value={this.state.BuscaM || ""}
                  className="form-control"
                />
                <button type="submit" className="btn btn-primary">
                  Buscar
                </button>
              </div>
            </form>

            <div className="mt-3">
              {this.state.rBuscaM ? (
                this.state.rBuscaM.length > 0 && (
                  <>
                    <table className="table table-striped table-bordered arvore-l">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">Código de barra</th>
                          <th scope="col">Nome</th>
                          <th scope="col">Opção</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.rBuscaM.map((item, index) => (
                          <tr key={index}>
                            <td>{item.codigo_barra}</td>
                            <td>{item.name}</td>
                            <td>
                              <button
                                className="btn btn-primary"
                                onClick={() => {
                                  console.log(this.state.products);
                                  console.log(item);
                                  const itemExists = this.state.products.some(
                                    (product) => product.id === item.id
                                  );

                                  if (itemExists) {
                                    toast.warn(
                                      "O equipamento já foi adicionado"
                                    );
                                  } else {
                                    this.setState({
                                      products: [
                                        ...this.state.products,
                                        {
                                          id: item.id,
                                          codigo_barra: item.codigo_barra,
                                          name: item.name,
                                          stockone: item.stockone,
                                          stocktwo: item.stocktwo,
                                          quantity: 1,
                                          price: {
                                            1: item.day_price,
                                            2: item.weekly_price,
                                            3: item.biweekly_price,
                                            4: item.monthly_price,
                                            5: item.annual_price,
                                          },
                                        },
                                      ],
                                      addEquipament: false,
                                      equipaments: [],
                                      rBuscaM: [],
                                      BuscaM: "",
                                    });
                                  }
                                }}
                              >
                                Selecionar
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )
              ) : (
                <div className="text-center">Nem um resultado encontrado</div>
              )}
            </div>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={this.state.showModal}
          title="Buscar cliente"
          onAfterClose={(result) => {
            this.setState({ showModal: false, rBusca: [], busca: "" });
          }}
        >
          <div className="content">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (this.state.busca.trim() == "") {
                  toast.warn("Digite algo para buscar");
                  e.target.classList.remove("load");
                  return;
                }
                fetch(`${apiUrl}/buscar-cliente?s=${this.state.busca}`, {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                  },
                })
                  .then((res) => {
                    e.target.classList.remove("load");
                    if (res.status === 404) {
                      this.setState({ rBusca: null });

                      return null;
                    }
                    if (!res.ok) {
                      throw new Error("Erro ao buscar os dados");
                    }
                    return res.json();
                  })
                  .then((data) => {
                    if (data) {
                      this.setState({ rBusca: data });
                    }
                  })
                  .catch((error) => {
                    e.target.classList.remove("load");
                    toast.error("Ocorreu um erro ao buscar o cliente");
                    console.error(error);
                  });
              }}
            >
              <div className="input-group">
                <select
                  className="form-control"
                  onChange={(e) => {
                    var value = e.target.value;
                    switch (value) {
                      case "1":
                        this.setState({ mask: "" });
                        break;
                      case "2":
                        this.setState({ mask: "999.999.999-99" });
                        break;
                      case "3":
                        this.setState({ mask: "99.999.999/9999-99" });
                        break;
                      case "4":
                        this.setState({ mask: "" });
                        break;
                      case "5":
                        this.setState({ mask: "(99) 9999-9999" });
                        break;
                      case "6":
                        this.setState({ mask: "(99) 9 9999-9999" });
                        break;
                    }
                  }}
                >
                  <option value="1">Nome</option>
                  <option value="2">CPF</option>
                  <option value="3">CNPJ</option>
                  <option value="4">Email</option>
                  <option value="5">Telefone</option>
                  <option value="6">Celular</option>
                </select>
                <InputMask
                  mask={this.state.mask}
                  placeholder="Digite..."
                  onChange={(e) => this.setState({ busca: e.target.value })}
                  value={this.state.busca || ""}
                  className="form-control"
                />
                <button type="submit" className="btn btn-primary">
                  Buscar
                </button>
              </div>
            </form>

            <div className="mt-3">
              {this.state.rBusca ? (
                this.state.rBusca.length > 0 && (
                  <>
                    <table class="table table-striped table-bordered arvore-l">
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col">Nome</th>
                          <th scope="col">Documento</th>
                          <th>Status</th>
                          <th scope="col">Opção</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.rBusca.length > 0 &&
                          this.state.rBusca.map((item, index) => (
                            <>
                              <tr>
                                <td>{item.nome}</td>
                                <td>{item.cnpj_cpf}</td>
                                <td>{this.TypeBlock(item.status)}</td>
                                <td>
                                  <button
                                    className="btn btn-primary"
                                    disabled={
                                      item.status == 1 || item.status == 3
                                        ? true
                                        : false
                                    }
                                    onClick={(e) => {
                                      this.setState((prevState) => ({
                                        dados: {
                                          ...prevState.dados, // Mantém os dados existentes
                                          cliente: {
                                            id: item.id,
                                            nome: item.nome,
                                            cnpj_cpf: item.cnpj_cpf,
                                          }, // Adiciona a nova propriedade cliente
                                        },
                                        showModal: false,
                                        rBusca: [],
                                        busca: "",
                                      }));
                                    }}
                                  >
                                    Selecionar
                                  </button>
                                </td>
                              </tr>
                            </>
                          ))}
                      </tbody>
                    </table>
                  </>
                )
              ) : (
                <div className="text-center">Nem um resultado encontrado</div>
              )}
            </div>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={this.state.newC}
          title="Cadastrar Cliente"
          onAfterClose={(result) => {
            this.setState({ newC: false });
          }}
        >
          <div className="container">
            <form
              id="cadastroForm"
              onSubmit={(e) => {
                var dataClient = this.state.dataClient;
                e.preventDefault();
                console.log(dataClient);
              }}
            >
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    label="Nome"
                    name="nome"
                    onChange={this.handlenCChange}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    label="CPF/CNPJ"
                    name="cpf_cnpj"
                    value={this.state.cpfCnpj}
                    onChange={(e) => this.handleCpfCnpjChange(e)}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <InputMask
                    name="telefone"
                    onChange={this.handlenCChange}
                    mask="(99) 9999-9999" // Máscara de telefone
                  >
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        label="Telefone"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          style: {
                            height: 45,
                            padding: "0 5px",
                          },
                        }}
                        margin="normal"
                      />
                    )}
                  </InputMask>
                </div>
                <div className="col-md-6">
                  <InputMask
                    name="celular"
                    onChange={this.handlenCChange}
                    mask="(99) 9 9999-9999" // Máscara de telefone
                  >
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        label="Celular"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          style: {
                            height: 45,
                            padding: "0 5px",
                          },
                        }}
                        margin="normal"
                      />
                    )}
                  </InputMask>
                </div>
              </div>
              <div>
                <TextField
                  label="E-mail"
                  name="email"
                  onChange={this.handlenCChange}
                  variant="outlined" // Pode ser "filled" ou "standard"
                  fullWidth // O campo ocupa toda a largura disponível
                  margin="normal"
                  InputProps={{
                    style: {
                      height: 45,
                      padding: "0 5px",
                    },
                  }}
                />
              </div>

              <div className="row">
                <div className="col-md-6">
                  <InputMask
                    name="cep"
                    onChange={this.handlenCChange}
                    mask="99.999-999" // Máscara de telefone
                  >
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        label="CEP:"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          style: {
                            height: 45,
                            padding: "0 5px",
                          },
                        }}
                        margin="normal"
                      />
                    )}
                  </InputMask>
                </div>
                <div className="col-md-6">
                  <TextField
                    label="Endereço"
                    name="endereco"
                    onChange={this.handlenCChange}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <TextField
                    label="Número"
                    name="numero"
                    onChange={this.handlenCChange}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    label="Bairro"
                    name="bairro"
                    onChange={this.handlenCChange}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <TextField
                    label="Cidade"
                    name="cidade"
                    onChange={this.handlenCChange}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    label="Estado"
                    name="estado"
                    onChange={this.handlenCChange}
                    variant="outlined" // Pode ser "filled" ou "standard"
                    fullWidth // O campo ocupa toda a largura disponível
                    margin="normal"
                    InputProps={{
                      style: {
                        height: 45,
                        padding: "0 5px",
                      },
                    }}
                  />
                </div>
              </div>
            </form>
          </div>

          <div className="modal-fotter">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                var dataClient = this.state.dataClient;

                fetch(`${apiUrl}/cadastrar-cliente`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    // outras headers, se necessário
                  },
                  body: JSON.stringify({
                    nome: dataClient.nome,
                    cnpj_cpf: this.state.cpfCnpj,
                    email: dataClient.email,
                    telefone: dataClient.telefone,
                    celular: dataClient.celular,
                    cep: dataClient.cep,
                    endereco: dataClient.endereco,
                    numero: dataClient.numero,
                    bairro: dataClient.bairro,
                    cidade: dataClient.cidade,
                    uf: dataClient.estado,
                  }), // corpo da requisição em formato JSON
                })
                  .then((response) => {
                    if (response.status == 200) {
                      response.json().then((data) => {
                        this.setState((prevState) => ({
                          dados: {
                            ...prevState.dados, // Mantém os dados existentes
                            cliente: {
                              id: data.id,
                              nome: dataClient.nome,
                              cnpj_cpf: this.state.cpfCnpj,
                            }, // Adiciona a nova propriedade cliente
                          },
                          newC: false,
                        }));

                        toast.success("Cliente adicionado");
                      });
                    }
                  })
                  .catch((err) => {
                    toast.error("Falha na comunicação com o servidor!");
                  });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>
      </div>
    );
  }
}

export default Lease;
