import React, { Component } from "react";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faPlus,
  faSave,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { apiUrl } from "../../../../../comps/apiUrl";
import withLocation from "../../../../../comps/withLocation";
import { StaticDialog } from "react-st-modal";

class EditLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      store: {},
      locations: [],
      itens: [],
      filteredItens: [],
      searchQuery: "",
      item: {},
    };
  }

  componentDidMount() {
    document.title = "Carregando...";
    fetch(
      `${apiUrl}/store/${this.props.params.token}/location/${this.props.params.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    )
      .then((response) => {
        if (response.status === 404) {
          this.setState({ loader: false });
        }

        if (response.status === 200) {
          response.json().then((data) => {
            document.title = `${data.store.name} > locações`;
            this.setState({
              item: data.location,
              store: data.store,
              loader: false,
            });
          });
        }
      })
      .catch(() => {
        toast.error("Ocorreu um erro ao carregar as máquinas");
      });
  }

  formatarMoeda = (valor) => {
    // Remove caracteres que não sejam números
    const apenasNumeros = valor.replace(/\D/g, "");
    // Converte para número e formata em BRL
    const valorFormatado = (Number(apenasNumeros) / 100).toLocaleString(
      "pt-BR",
      {
        style: "currency",
        currency: "BRL",
      }
    );
    return valorFormatado;
  };
  handleSearch = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    const filteredItens = this.state.itens.filter(
      (item) =>
        item.codigo_barra.toLowerCase().includes(searchQuery) ||
        item.descricao.toLowerCase().includes(searchQuery)
    );
    this.setState({ searchQuery, filteredItens });
  };

  render() {
    if (this.state.loader) {
      return (
        <div className="area-loader">
          <div className="loader"></div>
        </div>
      );
    }

    return (
      <div className="content d-flex justify-content-center">
        <div className="col-8 mt-5">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <strong>
                {this.state.store.name} <FontAwesomeIcon icon={faAngleRight} />{" "}
                Locações <FontAwesomeIcon icon={faAngleRight} /> Editar
              </strong>
            </div>
            <div className="card-body">
              <div className="content">
                <label className="mb-2">Nome do equipamento:</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.item.name}
                  disabled
                />

                <div className="row mt-3 mb-3">
                  <div className="col-md-6">
                    <label htmlFor="cpf" className="form-label">
                      Código de barra
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.item.codigo_barra}
                      disabled
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="rg" className="form-label">
                      Quantidade
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        this.setState((prevState) => ({
                          item: {
                            ...prevState.item, // Mantém os outros campos do objeto `dados`
                            ["qtd"]: e.target.value.replace(/\D/g, ""), // Atualiza apenas o campo correspondente
                          },
                        }));
                      }}
                      value={this.state.item.qtd}
                    />
                  </div>
                </div>

                <div className="row mt-3 mb-3">
                  <div className="col-md-4">
                    <label htmlFor="cpf" className="form-label">
                      Valor Diário
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        this.setState((prevState) => ({
                          item: {
                            ...prevState.item, // Mantém os outros campos do objeto `dados`
                            ["day_price"]: this.formatarMoeda(e.target.value), // Atualiza apenas o campo correspondente
                          },
                        }));
                      }}
                      value={this.state.item.day_price || "R$0,00"}
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="cpf" className="form-label">
                      Valor semanal
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        this.setState((prevState) => ({
                          item: {
                            ...prevState.item, // Mantém os outros campos do objeto `dados`
                            ["weekly_price"]: this.formatarMoeda(
                              e.target.value
                            ), // Atualiza apenas o campo correspondente
                          },
                        }));
                      }}
                      value={this.state.item.weekly_price || "R$0,00"}
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="rg" className="form-label">
                      Valor Quinzenal
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        this.setState((prevState) => ({
                          item: {
                            ...prevState.item, // Mantém os outros campos do objeto `dados`
                            ["biweekly_price"]: this.formatarMoeda(
                              e.target.value
                            ), // Atualiza apenas o campo correspondente
                          },
                        }));
                      }}
                      value={this.state.item.biweekly_price || "R$0,00"}
                    />
                  </div>
                </div>

                <div className="row mt-3 mb-3">
                  <div className="col-md-6">
                    <label htmlFor="cpf" className="form-label">
                      Valor Mensal
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        this.setState((prevState) => ({
                          item: {
                            ...prevState.item, // Mantém os outros campos do objeto `dados`
                            ["monthly_price"]: this.formatarMoeda(
                              e.target.value
                            ), // Atualiza apenas o campo correspondente
                          },
                        }));
                      }}
                      value={this.state.item.monthly_price || "R$0,00"}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="rg" className="form-label">
                      Valor Anual
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        this.setState((prevState) => ({
                          item: {
                            ...prevState.item, // Mantém os outros campos do objeto `dados`
                            ["annual_price"]: this.formatarMoeda(
                              e.target.value
                            ), // Atualiza apenas o campo correspondente
                          },
                        }));
                      }}
                      value={this.state.item.annual_price || "R$0,00"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => {
                  e.target.classList.add("load");

                  fetch(
                    `${apiUrl}/store/update/location/${this.state.item.id}`,
                    {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem("token"),
                      },
                      body: JSON.stringify(this.state.item), // corpo da requisição em formato JSON
                    }
                  )
                    .then((response) => {
                      e.target.classList.remove("load");
                      if (response.status == 200) {
                        response.json().then((resp) => {
                          toast.success("Equipamento atualizado com sucesso!");
                        });
                      }
                    })
                    .catch((err) => {
                      toast.error("Falha na comunicação com o servidor!");
                    });
                }}
              >
                <FontAwesomeIcon icon={faSave} /> Atualizar
              </button>
            </div>
          </div>
        </div>
        <StaticDialog
          isOpen={this.state.itens.length > 0}
          title="Selecione o equipamento"
          onAfterClose={() => {
            this.setState({ itens: [], filteredItens: [], searchQuery: "" });
          }}
        >
          <div className="content">
            <form>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pesquisar"
                  value={this.state.searchQuery}
                  onChange={this.handleSearch}
                />
                <button type="button" className="btn btn-primary">
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              </div>
            </form>
            <div>
              {this.state.filteredItens.length > 0 ? (
                <table className="table table-striped table-bordered arvore-l mt-2">
                  <thead className="thead-dark">
                    <tr>
                      <th>Código de barra</th>
                      <th>Descrição</th>
                      <th>Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.filteredItens.map((item) => (
                      <tr key={item.codigo_barra}>
                        <td>{item.codigo_barra}</td>
                        <td>{item.descricao}</td>
                        <td>
                          <button
                            className="btn btn-primary"
                            onClick={() => this.setState({ item, itens: [] })}
                          >
                            Selecionar
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="text-center mt-3">
                  <p>Nenhum item encontrado.</p>
                </div>
              )}
            </div>
          </div>
        </StaticDialog>
      </div>
    );
  }
}

export default withLocation(EditLocation);
