import React, { Component } from "react";
import withLocation from "../../../comps/withLocation";
import { apiUrl } from "../../../comps/apiUrl";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";

class EditClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      data: {},
    };
  }
  componentDidMount() {
    document.title = "Editar Cliente";
    fetch(`${apiUrl}/view-client/${this.props.params.token}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status == 404) {
        this.setState({
          loader: false,
          error: true,
        });
      }

      response.json().then((data) => {
        this.setState({
          data,
          loader: false,
        });
      });
    });
  }
  render() {
    if (this.state.loader) {
      return (
        <div class="area-loader">
          <div class="loader"></div>
        </div>
      );
    }
    return (
      <div className="content">
        <div className="card">
          <div className="card-header d-flex align-items-center justify-content-between">
            <strong style={{ fontSize: 20 }}>Editar Cliente</strong>
            <Link
              to={`/cadastro-clientes/edit/${this.props.params.token}`}
              className="btn btn-primary"
            >
              <FontAwesomeIcon icon={faPen} /> Editar
            </Link>
          </div>
          <div className="card-body ">
            {this.state.data.tipo_pessoa == "F" ? (
              <>
                <div className="card">
                  <div className="card-body">
                    <div>
                      <div className="mb-2">
                        <span>Informações Pessoais:</span>
                      </div>
                      <p>
                        <strong>Nome:</strong> {this.state.data.nome}
                      </p>
                      <p>
                        <strong>CPF:</strong> {this.state.data.cnpj_cpf}
                      </p>
                      <p>
                        <strong>RG:</strong>{" "}
                        {this.state.data.rg != "" ? (
                          this.state.data.rg
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>
                      <div className="mb-2">
                        <span>Contato:</span>
                      </div>
                      <p>
                        <strong>Telefone fixo:</strong>{" "}
                        {this.state.data.telefone != "" ? (
                          this.state.data.telefone
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>

                      <p>
                        <strong>Celular: </strong>
                        {this.state.data.celular != "" ? (
                          this.state.data.celular
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>
                      <p>
                        <strong>Email:</strong>
                        {this.state.data.email != "" ? (
                          this.state.data.email
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>
                      <div className="mb-2">
                        <span>Endereço:</span>
                      </div>
                      <p>
                        <strong>Endereço: </strong>
                        {this.state.data.endereco != "" ? (
                          this.state.data.endereco
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>
                      <p>
                        <strong>Número: </strong>
                        {this.state.data.numero != "" ? (
                          this.state.data.numero
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>

                      <p>
                        <strong>Bairro: </strong>
                        {this.state.data.bairro != "" ? (
                          this.state.data.bairro
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>

                      <p>
                        <strong>Cidade: </strong>
                        {this.state.data.cidade != "" ? (
                          this.state.data.cidade
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>

                      <p>
                        <strong>Estado: </strong>
                        {this.state.data.uf != "" ? (
                          this.state.data.uf
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>

                      <p>
                        <strong>Cep: </strong>
                        {this.state.data.cep != "" ? (
                          this.state.data.cep
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="card">
                  <div className="card-body">
                    <div>
                      <div className="mb-2">
                        <span>Informações Pessoais:</span>
                      </div>
                      <p>
                        <strong>Nome:</strong> {this.state.data.nome}
                      </p>
                      <p>
                        <strong>CNPJ:</strong> {this.state.data.cnpj_cpf}
                      </p>
                      <p>
                        <strong>Inscrição estadual:</strong>{" "}
                        {this.state.data.inscricao_estadual != "" ? (
                          this.state.data.inscricao_estadual
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>
                      <div className="mb-2">
                        <span>Contato:</span>
                      </div>
                      <p>
                        <strong>Telefone fixo:</strong>{" "}
                        {this.state.data.telefone != "" ? (
                          this.state.data.telefone
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>

                      <p>
                        <strong>Celular: </strong>
                        {this.state.data.celular != "" ? (
                          this.state.data.celular
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>
                      <p>
                        <strong>Email:</strong>
                        {this.state.data.email != "" ? (
                          this.state.data.email
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>
                      <div className="mb-2">
                        <span>Endereço:</span>
                      </div>
                      <p>
                        <strong>Endereço: </strong>
                        {this.state.data.endereco != "" ? (
                          this.state.data.endereco
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>
                      <p>
                        <strong>Número: </strong>
                        {this.state.data.numero != "" ? (
                          this.state.data.numero
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>

                      <p>
                        <strong>Bairro: </strong>
                        {this.state.data.bairro != "" ? (
                          this.state.data.bairro
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>

                      <p>
                        <strong>Cidade: </strong>
                        {this.state.data.cidade != "" ? (
                          this.state.data.cidade
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>

                      <p>
                        <strong>Estado: </strong>
                        {this.state.data.uf != "" ? (
                          this.state.data.uf
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>

                      <p>
                        <strong>Cep: </strong>
                        {this.state.data.cep != "" ? (
                          this.state.data.cep
                        ) : (
                          <span style={{ color: "red" }}> Não cadastrado</span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withLocation(EditClient);
